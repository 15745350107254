@import 'styles/common.scss';
.session_phone_call_number {
  text-align: center;
  @include Montserrat(24px, var(--main-gray), 500, 120%);
  @include phone() {
    @include Montserrat(16px, var(--main-gray), 500, 120%);
  }
}
.modal-container{
&.error{
.modal{
  .modal-inner-container{
    .modal-title{
      color: var(--error-alert);
    }
  }
}
}
}
