@import 'styles/common.scss';

.account_deletion__modal {
  .modal_block {
    &__title {
      font-size: 32px !important;
      color: var(--error-alert) !important;
      margin-bottom: 40px !important;
      @include phone() {
        font-size: 24px !important;
        margin-bottom: 24px !important;
      }
    }

    &__header {
        margin-bottom: unset !important;
    }

    &__body {
        display: flex;
        gap: 24px;
        flex-direction: column;
        .content {
          display: flex;
          gap: 24px;
          flex-direction: column;
        }
        p {
          text-align: left !important;
          @include Roboto(20px, var(--text-secondary), 400, 150%);
          @include phone() {
            @include Roboto(16px, var(--text-secondary), 400, 150%);
          }
          span {
            @include Roboto(20px, var(--text-secondary), 700, 150%);
            @include phone() {
              @include Roboto(16px, var(--text-secondary), 700, 150%);
            }
          }
        }
        .checkbox {
          display: flex;
          flex-direction: row;
          align-items: center;
          &:hover {
            cursor: pointer;
          }
          p {
            margin-bottom: 0px;
            @include phone() {
              @include Roboto(16px, var(--text-secondary), 400, 180%);
            }
          }
        }
        .selectable_box {
          opacity: 0.5;
          width: 24px;
          height: 24px;
          font-size: 24px;
          border: 0;
          background-color: var(--background-primary-light);
          border: 2px solid var(--button-radio-button-check-box);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          margin-right: 10px;
          
          &.active {
            opacity: 1;
            background: var(--button-radio-button-check-box);
            border: 2px solid var(--button-radio-button-check-box);
          }
          
          @include phone() {
            bottom: 8px;
            right: 8px;
          }
        }
    }

    &__acceptButton .btn {
      background-color: var(--error-alert);
      border: 1px solid var(--border-tetriary-button);
      width: 205px;
      height: 48px;
      @include phone {
        width: 160px;
        height: 40px;
        padding: 8px 16px;
      }
      .children {
        @include Roboto(20px, var(--text-on-the-dark-background), 500, 150%);
        @include phone() {
          @include Roboto(16px, var(--text-on-the-dark-background), 500, 150%);
        }
      }
    }

    &__declineButton .btn {
      background-color: var(--button-tetriary-active);
      border: 1px solid var(--border-tetriary-button);
      width: 140px;
      height: 48px;
      @include phone {
        width: 160px;
        height: 40px;
        padding: 8px 16px;
      }
      .children {
        @include Roboto(20px, var(--text-buttons-secondary), 500, 150%);
        @include phone() {
          @include Roboto(16px, var(--text-buttons-secondary), 500, 150%);
        }
      }
    }
    &__closeIcon {
      left: unset !important;
      right: 16px !important;
      top: 16px !important;
    }
    &__footer .modal-footer-buttons {
      gap: 16px !important;
      @include phone {
        justify-content: center;
      }
    }
  }
}
.deletion_modal_title {
  color: var(--text-accent-1) !important;
}
