@import 'styles/common.scss';

.header_wrapper .button_wrapper {
  // width: unset !important;
  width: unset;
}

.profile_notifications {
  &__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: var(--button-notification-unselected);
    border-radius: 50%;

    &:hover svg path {
      fill: var(--background-primary-light);
    }

    &.open {
      background: var(--button-notification-selected);

      svg path {
        fill: var(--main-gray);
      }
    }

    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      position: relative;

      .circle {
        top: -3px;
        right: -3px;
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--error-alert);
      }
    }
  }

  &__popup {
    position: absolute;
    top: 60px;
    right: 5px;

    @include phone() {
      right: 10px;
    }
  }

  &__container {
    width: 516px;

    @include phone() {
      width: calc(100vw - 20px);
    }

    max-height: 650px;

    overflow: scroll;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 0;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15));
    border-radius: 10px;
    background: var(--shades-1);

    flex-grow: 0;
    order: 0;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    @include phone() {
      max-height: 400px;
    }

    &__inner {
      padding: 24px;
      width: 100%;

      &__top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;

        &__title {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 120%;

          font-feature-settings:
            'pnum' on,
            'lnum' on;

          color: var(--main-dark-gray);
        }

        &__markread {
          text-decoration: underline;
          color: var(--main-blue);
        }
      }
    }

    &__footer {
      width: 100%;
      padding: 12px 0;
      background: var(--shades-2);
      display: flex;
      justify-content: center;

      .button_wrapper {
        width: unset !important;
      }
    }

    .profile_notification__card {
      padding: 12px 12px 12px 16px;

      border: 1px solid var(--shades-3);
      background-color: var(--main-white);
      border-radius: 8px;
      width: 458px;

      @include phone() {
        width: 327px;
      }
    }
  }
}
