@import 'styles/common.scss';

.word_definition_tooltip {
  .tooltip__content__rectangle ul>li::marker {
    color: var(--icon-secondary);
  }

  .tooltip__content__rectangle, .tooltip__content__rectangle ul > li {
    @include Roboto(12px, var(--text-primary1), 400, normal);

    a {
      color: var(--text-accent-1) !important;
      text-decoration: underline;
      font-size: 12px !important;
    }
  }
}
