@import 'styles/common.scss';

.code_verification_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__text {
    max-width: 650px;
    
    margin-top: 100px;
    margin-bottom: 32px;
    
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;

    &__title {
      text-align: center;
      letter-spacing: -0.02em;

      @include Montserrat(32px, var(--text-primary1), 500, 120%);
      
      @include phone() {
        @include Montserrat(24px, var(--text-primary1), 500, 120%);
      }
    }
     
    &__subtitle {
      max-width: 420px;
      text-align: center;

      @include Roboto(20px, var(--text-secondary), 400, 150%);
      
      @include phone() {
        @include Roboto(16px, var(--text-secondary), 400, 150%);
        text-align: center;
      }
    }
  }

  &__input {
    max-width: 380px;
    margin-bottom: 32px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  
  &__resend_code_btn {
    margin-top: 34px;

    .btn {
      @include Roboto(16px, var(--text-primary1), 400, 120%)
    }
  } 

  &__go_back_btn {
    margin-top: 12px;
    .btn {
      @include Roboto(16px, var(--text-primary1), 400, 120%)
    }
  }

  .button_wrapper__icon .btn .children {
    @include Roboto(20px, var(--text-buttons-primary), 500);
    @include phone() {
      @include Roboto(16px, var(--text-buttons-primary), 500);
    }
  }
}