@import 'styles/common.scss';

.loan_card_modal_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  // .tags {
  //   display: flex;
  //   flex-direction: column;
  //   gap: 8px;
  // }

  .card {
    width: 100%;
    display: flex;
    flex-direction: column;


    .card_header {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      padding: 16px 40px;
      background: var(--background-secondary) !important;
      border-radius: 8px 8px 0px 0px;
      text-transform: capitalize;

      @include phone {
        padding: 12px 16px !important;
        justify-content: left;
      }
  
      .card_header_icon {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        align-self: center;
      }
      .card_header_text {
        @include Montserrat(24px, var(--text-primary1), 500, 120%);
        @include phone {
          @include Montserrat(18px, var(--text-primary1), 500, 120%);
        }
      }
    }

    .card_body {
      padding: 32px 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 16px;
      max-height: 100%;
      border-right: 2px solid var(--border-funding);
      border-left: 2px solid var(--border-funding);
      @media screen and (max-width: 647px) {
        padding: 24px 16px;
        gap: 24px;
        flex-direction: column;
      }

      .card_item {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 4px;
        width: 239px;
        padding: 16px 12px;
        border-radius: 8px;
        background: var(--background-primary-light);

        @media screen and (max-width: 647px) {
          padding: 0px;
        }

        &__title_with_tooltip {
          display: flex;
          align-items: center;
          column-gap: 4px;

          .tooltip_basic_requirements {

            .tooltip__content {
              @include adaptive(600) {
                left: -147px;
              }

              // &__triangle {
              //   @include adaptive(600) {
              //     left: 150px;
              //   }
              // }
              
              &__rectangle {
                // width: 400px;
  
                // @include adaptive(600) {
                //   width: 310px;
                // }
              }
            }
          }
  
          // .tooltip__question_mark svg {
          //   margin-bottom: 6px;

          //   @include phone() {
          //     margin-bottom: 5px;
          //   }
          // }
        }

        p {
          &:nth-child(1) {
            @include Roboto(16px, var(--text-accent-1), 500, 140%);
            text-align: left;
            width: 100%;
            @include phone(){
            @include Roboto(14px, var(--text-accent-1), 500, 140%);
            }
          }
          &:nth-child(2) {
            @include Roboto(20px, var(--text-primary1), 500, 140%);
            text-align: left;
            width: 100%;
          }
        }
      }

      .card_item:nth-child(1) {
        width: 100%;
        background: none;
        height: auto;
        padding: 0px;

        p {
          @include Roboto(16px, var(--text-primary2), 500, 140%);
          text-align: center;

          @media screen and (max-width: 647px) {
            text-align: left;
          }
        }
        @media screen and (max-width: 647px) {
          margin-bottom: -8px;
        }
      }

      .card_item:nth-child(10) {
        width: 100% !important;
        text-align: left !important;
        p:nth-child(2) {
          @include Roboto(16px, var(--text-primary1), 400, 140%);
        }
      }

      .card_item:nth-child(11) {
        width: 100% !important;
        align-self: flex-start;
        p {
          @include Roboto(16px, var(--text-accent-1), 500, 140%);
          text-align: left;
          @include phone(){
            @include Roboto(14px, var(--text-accent-1), 500, 140%);
          }
        }
        ul {
          text-align: left;
          padding-left: 25px;
          width: 100%;
          @include Roboto(16px, var(--text-primary1), 400, 140%);
          li > span {
            @include Roboto(16px, var(--text-primary1), 500, 140%);
          }
        }
      }

      .card_item:nth-child(12) {
        width: 100% !important;
        align-self: flex-start;
        padding: 12px;
        @media screen and (max-width: 647px) {
          padding: 0px;
        }
        p {
          @include Roboto(16px, var(--text-accent-1), 500, 140%);
          @include phone(){
            @include Roboto(14px, var(--text-accent-1), 500, 140%);
          }
        }
    
        ul {
          text-align: left;
          padding-left: 25px;
          width: 100%;
          @include Roboto(16px, var(--text-primary1), 400, 140%);
        }
        span {
          align-self: start; 
          @include Roboto(16px, var(--text-primary1), 400, 140%);
        }
      }
    }
  }
}
