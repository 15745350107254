@import 'styles/common.scss';

.advisor-referral-notification-container {
  p {
    @include Roboto(14px, var(--text-primary1), 400, 160%);
    margin-bottom: 12px;
  }

  .referral_button {
    width: fit-content !important;
    @include phone {
      width: 100% !important;
    }
    .btn {
      padding: 8px 16px;
      @include Roboto(14px, var(--text-buttons-secondary), 500, 160%);
      background-color: var(--button-secondary);
      border: none;
    }
  }
}
