@import "../../../styles/common.scss";

.select_button_hint {
  position: absolute;
  right: 14px;
  bottom: 14px;

  @include phone() {
    right: 10px;
    bottom: 8px;
  }

  &.position_left {
    .select_button_hint__block {
      &__wrapper {
        right: 10px;
        bottom: 8px;
      }

      &__popup {
        top: 35px;
        right: -15px;
        left: initial;
      }

      &__triangle {
        &>div {
          &:nth-child(1) {
            left: initial;
            right: 20px;
          }

          &:nth-child(2) {
            left: initial;
            right: 19px;
          }

          &:nth-child(3) {
            left: initial;
            right: 15px;
          }
        }
      }
    }
  }

  &__block {
    position: relative;

    &__popup {
      display: none;
      position: absolute;
      top: 37px;
      left: -15px;
      width: 227px;
      background: var(--main-white);
      border: 2px solid var(--shades-5);
      padding: 8px 16px 8px 8px;
      border-radius: 5px;

      &.open {
        display: block;
        z-index: 9999;
      }

      @include phone() {
        top: 35px;
        right: -15px;
        left: initial;
      }
    }

    &__text {
      @include Roboto(13px, var(--main-gray), 500, 120%);
    }

    &__triangle {
      &>div {
        &:nth-child(1) {
          width: 12px;
          border: 1px solid var(--shades-5);
          position: absolute;
          left: 15px;
          z-index: 2;
          background: var(--shades-5);
          top: -7px;
          border-radius: 1px 0px 1px 1px;
          transform: rotate(120deg);

          @include phone() {
            left: initial;
            right: 20px;
          }
        }

        &:nth-child(2) {
          width: 9px;
          border: 2px solid white;
          position: absolute;
          left: 19px;
          z-index: 1;
          top: -2px;
          border-radius: 0px 1px 0px 0px;

          @include phone() {
            left: initial;
            right: 19px;
          }
        }

        &:nth-child(3) {
          width: 12px;
          border: 1px solid var(--shades-5);
          position: absolute;
          left: 20px;
          z-index: 2;
          background: var(--shades-5);
          top: -7px;
          transform: rotate(62deg);
          border-radius: 1px 1px 0px 1px;

          @include phone() {
            left: initial;
            right: 15px;
          }
        }
      }
    }
  }
}