@import "styles/common.scss";

.calendly_links_modal_title {
  color: orange !important;
}

.sync_calendly_links_form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}