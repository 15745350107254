@import 'styles/common.scss';
.cohort_modal_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    .card_header {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      padding: 16px 40px;
      background: var(--background-secondary) !important;
      border-radius: 8px 8px 0px 0px;
      text-transform: capitalize;
      @include phone {
        padding: 12px 16px !important;
        justify-content: left;
      }
      .card_header_icon {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        align-self: center;
      }
      .card_header_text {
        @include Montserrat(24px, var(--text-primary1), 500, 120%);
        @include phone {
          @include Montserrat(18px, var(--text-primary1), 500, 120%);
        }
      }
    }

  .fee{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    padding: 4px 6px;
    width: fit-content;
    background: var(--switcher-unselected) !important;
    @include Roboto(16px, var(--text-primary1), 400, 100%);
    white-space: nowrap;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-left: 17px;
    @include phone() {
     align-self: flex-start;
      }
  }

  .dollar_icon{
    height: 20px;
    width: 10px;
    margin-inline-start: 4px;
  }

  .no_dollar_icon{
    height: 20px;
    width: 14px;
    margin-inline-end: 5px;
  }


    .deadline {
      align-self: center;
      padding: 0.5rem;
      border: 1px solid lightgray;
      border-radius: 7px;
      display: flex;
      flex-direction: row;
      align-items: top;
      justify-content: center;
      width: fit-content;
      margin-bottom: 2rem;
      span {
        @include Roboto(14px, var(--text-secondary), 500, 140%);
        margin-top: 4px;
        margin-left: 4px;
      }
    }
    .card_body {
      padding: 32px 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 16px;
      max-height: 100%;
      @media screen and (max-width: 647px) {
        padding: 24px 16px;
        gap: 24px;
        flex-direction: column;
      }
      .name {
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 4px;
        width: 100%;
        padding-bottom: 1rem;
        border-bottom: 2px solid lightgray;
        @include phone() {
          padding-bottom: 0px;
          align-self: center;
        }
        p {
          &:nth-child(1) {
            @include Roboto(18px, var(--text-primary1), 400, 140%);
            @include phone {
              min-height: 44px;
              @include Montserrat(18px, var(--text-primary1), 500, 120%);
            }
          }
          span {
            @include Roboto(16px, var(--text-accent-1), 500, 140%);
          }
        }
      }
      .desription {
        width: 100%;
        @include Roboto(16px, var(--text-secondary), 400, 140%);
        text-align: left;
        margin-bottom: 24px;
        overflow: hidden;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        span {
          @include Roboto(16px, var(--text-accent-1), 500, 140%);
        }
      }
      .card_item {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 4px;
        width: 239px;
        padding: 16px 12px;
        border-radius: 8px;
        background: var(--background-primary-light);
        @media screen and (max-width: 647px) {
          padding: 0px;
        }
        p {
          &:nth-child(1) {
            @include Roboto(16px, var(--text-accent-1), 500, 140%);
            text-align: left;
            width: 100%;
            @include phone(){
            @include Roboto(16px, var(--text-accent-1), 500, 140%);
            }
          }
          &:nth-child(2) {

            @include Roboto(22px, var(--text-primary1), 500, 140%);
          }
          &:nth-child(3) {
            @include Roboto(16px, var(--text-primary1), 500, 140%);
            text-align: left;
            width: 100%;
          }
          
        }
      }

      .card_item {
        &:nth-child(6),
        &:nth-child(9),
        &:nth-child(11) {
          p {
            text-align: left;
          }
        }
      }

      .card_item:nth-child(7) {
        width: 100% !important;
        text-align: center !important;
        p {
          @include Roboto(16px, var(--text-primary1), 500, 140%);
          text-align: center;
          @include phone(){
            @include Roboto(16px, var(--text-buttons), 500, 140%);
            text-align: left;

          }
        }
      }

      .card_item:nth-child(12) {
        width: 100% !important;
        align-self: flex-start;
        padding: 12px;
        @media screen and (max-width: 647px) {
          padding: 0px;
        }
        p {
          @include Roboto(16px, var(--text-accent-1), 500, 140%);
          @include phone(){
            @include Roboto(14px, var(--text-accent-1), 500, 140%);
          }
        }
        
        ul {
          text-align: left;
          padding-left: 25px;
          width: 100%;
          @include Roboto(16px, var(--text-primary1), 400, 140%);
        }
        span {
          align-self: start; 
          @include Roboto(16px, var(--text-primary1), 400, 140%);
          p {
            @include Roboto(22px, var(--text-primary1), 500, 140%);
          }
        }
      }
    }
  }
}
