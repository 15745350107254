@import 'styles/common.scss';

.zip_codes_file_import_modal {
  &__description {
    p {
      @include Roboto(20px, var(--main-gray, #676767), 400, 150%);
      text-align: center;
      font-variant-numeric: lining-nums proportional-nums;
      font-feature-settings: 'clig' off, 'liga' off;

      @include phone() {
        font-size: 16px;
      }
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    &-button {
      margin-bottom: 24px !important;

      .btn {
        padding: 8.5px 12px 9.5px 12px !important;
        height: 32px !important;
      }
    }

    &-icon {
      margin-bottom: 8px;
    }

    &-loader {
      margin-top: 17px;
      margin-bottom: 50px;
    }
  }

  &__error_description {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 25px;

      &__icon {
        width: 20px;
        height: 20px;
      }

      &__text {
        @include Roboto(20px, var(--error-alert, #BA2C2B), 400, 150%);
        text-align: center;
        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings: 'clig' off, 'liga' off;

        @include phone() {
          font-size: 16px;
        }
      }
    }

    &__text {
      margin-bottom: 25px;
      @include Roboto(16px, var(--main-gray, #676767), 400, 150%);
      text-align: center;
      font-variant-numeric: lining-nums proportional-nums;
      font-feature-settings: 'clig' off, 'liga' off;
      letter-spacing: -0.16px;
    }
  }

  &__success_description {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    @include phone() {
      padding-left: 40px;
      padding-right: 40px;
    }

    svg {
      width: 20px;
      height: 20px;

      @include phone() {
        width: 16px;
        height: 16px;
      }
    }

    p {
      @include Roboto(20px, var(--secondary-teal, #099), 400, 150%);
      text-align: center;
      font-variant-numeric: lining-nums proportional-nums;
      font-feature-settings: 'clig' off, 'liga' off;

      @include phone() {
        font-size: 16px;
        width: 190px;
      }
    }

    margin-bottom: 8px;
  }

  &.modal {
    .modal-inner-container {
      margin-bottom: 0;
    }

    .modal-footer {
      background-color: var(--main-white);
      border-radius: 8px;

      .modal-close-button {
        margin-top: 16px;
        margin-bottom: 40px;

        @include phone() {
          margin-bottom: 32px;
        }
      }
    }
  }
}