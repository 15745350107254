@import 'styles/common.scss';

.cohort_resource_modal {
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-bottom: 48px;

    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      border-radius: 90px;
      background: var(--background-glossary);
    }
    p {
      @include Montserrat(32px, var(--text-primary1), 500, 130%);
    }
  }

  &__content {
    .advisory-org-zip-codes {
      .profile_field__title {
        display: none;
      }
    }

    #selctors_container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 32px;

      .select_button {
        width: 100%;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      width: 240px;
      height: 48px;
      padding: 8px 16px;
      @include Roboto(20px, var(--text-buttons-primary), 500, 140%);
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .custom_sub_title {
    @include Roboto(16px, var(--text-primary1), 400, 140%);

    @include phone() {
      @include Roboto(16px, var(--text-primary1), 400, 140%);
    }
  }

  .custom_field_class{
    margin-bottom: 18px;
  }

  .errors_text {
    margin-bottom: 10px;
  }
}
