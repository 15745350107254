@import 'styles/common.scss';

.global-error-page-wrapper {
  margin-bottom: unset;
}

.global-error-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;

  &__image {
    width: 300px;
    height: 300px;

    @include phone() {
      width: 300px;
      height: 300px;
    }
  }

  &__content {
    text-align: center;
    max-width: 620px;

    @include phone() {
      max-width: 320px;
    }

    &__title {
      @include Montserrat(36px, var(--main-blue), 600, 120%);

      @include phone() {
        @include Montserrat(32px, var(--main-blue), 600, 120%);
      }
    }

    &__text {
      margin-top: 32px;

      @include Roboto(24px, var(--main-blue), 400, 150%);

      @include phone() {
        @include Roboto(20px, var(--main-blue), 400, 150%);
      }
    }
  }
}