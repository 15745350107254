@import 'styles/common.scss';
.modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(var(--neutral-1-rgb), 0.7);
  z-index: 9999;

  .modal_block {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: auto;
    min-width: 540px;
    max-width: 1112px;
    max-height: 738px;
    height: 100%;
    background: var(--main-white);
    padding: 0;
    align-items: center;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    @include phone() {
      max-width: 100%;
      min-width: 390px;
      width: 100%;
      min-width: 100%;
      margin-bottom: 0;
      border-radius: 8px 8px 0 0;
    }

    &.modal_calendly {
      width: 100%;
      height: 100%;
      background: transparent;
    }

    .modal_close_button {
      position: absolute;
      display: none;
      top: 10px;
      right: 10px;
      border-radius: 50%;
      overflow: hidden;
      z-index: 101;

      &.show {
        display: block;
      }

      .filled {
        padding: 6px 6px;
        height: auto;
        background-color: var(--main-gray);

        svg {
          margin-left: 1px;
          margin-bottom: 1px;
        }
      }

      @include phone {
        display: block;
      }
    }

    .modal_video {
      width: 532px;
      height: 100%;
      display: flex;

      .video_player {
        width: 100%;
        border-radius: 8px 0 0 8px;

        video {
          border-radius: 8px 0 0 8px;
        }
      }

      @include phone {
        display: none;
      }

      &.phone_video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        z-index: 100;
      }
    }

    .modal_right_content {
      display: flex;
      flex-direction: column;
      width: 580px;
      max-width: 580px;
      height: 100%;
      @include phone() {
        width: 100%;
        max-width: unset;
      }
      .modal_watch_intro {
        display: none;

        .filled-gray {
          margin: 12px auto 16px;
        }

        @include phone() {
          display: block;
        }
      }

      .modal_header {
        padding: 32px;

        @include phone() {
          padding: 24px 16px 0;
        }

        &_user {
          display: flex;
          justify-content: center;

          &_avatar {
            margin-right: 8px;
          }

          &_info {
            display: flex;
            flex-direction: column;
            justify-content: center;

            &_name {
              margin: 0;
              @include Montserrat(24px, var(--main-dark-gray), 500, 120%);
            }

            &_company {
              margin: 0;

              @include Roboto(16px, var(--shades-7), 400, 150%);
            }

            @include phone() {
              &_name,
              &_company {
                font-size: 15px;
              }
            }
          }
        }

        &_description {
          max-width: 256px;
          text-align: center;
          margin: 8px auto 0;

          @include truncate(2);
          @include Roboto(16px, var(--main-gray), 400, 150%);

          @include phone() {
            margin-bottom: 16px;
            font-size: 15px;
          }
        }
      }

      .modal_body {
        width: 100%;
        flex-grow: 1;
        padding: 24px 48px;
        text-align: center;
        background: var(--shades-1);
        border-top: 1px solid var(--shades-3);
        overflow: auto;

        @include phone() {
          padding: 24px;
        }

        &_title {
          margin-block: 0 24px;

          @include Montserrat(24px, var(--main-dark-gray), 500, 120%);

          @include phone() {
            font-size: 18px;
          }
        }

        &_subtitle {
          margin-bottom: 35px;

          @include Roboto(20px, var(--main-gray), 400, 150%);

          @include phone() {
            font-size: 16px;
          }
        }

        &_meeting_link {
          display: block;
          padding: 12px 20px;
          background-color: var(--main-white);
          border: 1px dashed var(--secondary-teal);
          border-radius: 4px;
          text-decoration: none;

          &_text {
            @include truncate;

            @include Roboto(20px, var(--secondary-teal), 400, 150%);

            @include phone() {
              font-size: 16px;
            }
          }
        }

        &_meeting_summary {
          max-width: 484px;
          margin: 50px auto 0;
          background-color: var(--main-white);
          border: 1px solid var(--shades-13);
          border-radius: 8px;

          &_item {
            padding: 12px 24px;
            text-align: left;

            &:not(:last-of-type) {
              border-bottom: 1px solid var(--shades-13);
            }

            .name {
              max-width: 167px;
              width: 100%;
              display: inline-block;

              @include Roboto(20px, var(--main-gray), 400, 150%);

              @include phone() {
                max-width: 131px;
              }
            }

            .content {
              @include Roboto(20px, var(--main-dark-gray), 400, 150%);
            }

            @include phone() {
              .name,
              .content {
                font-size: 16px;
              }
            }
          }
        }

        .select {
          display: flex;
          align-items: center;
          padding: 16px 24px;
          background: var(--main-white);
          border: 2px solid var(--shades-6);
          border-radius: 4px;
          cursor: pointer;
          margin-bottom: 8px;

          @include phone() {
            padding: 10px 16px;
          }

          &.active {
            border: 2px solid var(--secondary-teal);
          }

          .selectable_block {
            &__circle {
              position: relative;
              top: 0;
              order: 1;
              margin-left: 12px;
            }

            &__title {
              display: inline-flex;
              flex-grow: 1;
              order: 2;

              @include Roboto(20px, var(--main-gray), 400, 150%);
            }

            &__text {
              display: inline-flex;
              order: 3;

              @include Roboto(20px, rgba(var(--main-gray-rgb), 0.8), 400, 150%);
            }

            @include phone() {
              &__title,
              &__text {
                font-size: 16px;
              }
            }
          }
        }

        .popper_icon {
          margin-bottom: 6px;
          font-size: 60px;

          @include phone() {
            font-size: 32px;
          }
        }
      }

      .modal_footer {
        width: 100%;
        padding: 14px;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
        background-color: var(--shades-2);
        border-top: 1px solid var(--shades-3);

        @include phone() {
          padding: 7px;
        }

        .buttons_row {
          display: flex;

          .cancel_button {
            margin-block: auto;

            .text {
              border: none;
              @include Montserrat(14px, var(--main-gray), 700, 100%);
            }
          }
        }

        .step_counter {
          justify-self: center;

          @include Roboto(20px, var(--main-gray), 400, 150%);

          @include phone() {
            font-size: 16px;
            margin-left: 0;
          }
        }
        .button_next {
          justify-self: end;
        }
        &.stepFinal {
          .buttons_row{
            display: none;
          }
          .step_counter{
            display: none;
          }
          .button_next {
            justify-self: center;
          }
        }
      }
    }
  }
}

.isCFO_modal_wrapper {
  .modal_header_user_info_company{
    color:  #DF643A !important;
  }
  .sb-avatar__text{
    background:  #DF643A !important;
  }
  .selectable_block__circle{
    border-color:  #DF643A !important;
  }
  .button_next{
    .btn{
      background-color:  #DF643A !important;
    }
  }
}
