@import 'styles/common.scss';

.footer_wrapper {
    height: 44px;
    margin-bottom: 15px;

    @include phone {
        height: 204px;
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid var(--line-footer);
    }
}
