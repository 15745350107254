@import 'styles/common.scss';
.avatar__img {
  .sb-avatar {
    span {
      text-align: center;
      @include Roboto(20px, var(--text-on-the-dark-background), 700, 140%);
      @include phone() {
        @include Roboto(16px, var(--text-on-the-dark-background), 700, 140%);
      }
      color: var(--text-on-the-dark-background) !important;
    }
  }
}
