@import 'styles/common.scss';

.video_player {
  position: relative;
  display: flex;
  background: rgba(var(--neutral-1-rgb), 1);
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  video{
    border-radius: 8px;
  }

  &__play {
    position: absolute;
    bottom: 0;
    right: 100px;
  }

  &__micro {
    position: absolute;
    bottom: 0;
    right: 70px;
  }

  &__screen {
    position: absolute;
    bottom: 0;
    right: 10px;
  }
}
