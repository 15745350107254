@import 'styles/common.scss';

.read_more,
.read_less {
  border: none;
  background-color: var(--main-white);
  @include Roboto(16px, var(--main-gray), 500, 150%);
  cursor: pointer;
  @include phone {
    @include Roboto(16px, var(--main-blue), 500, 150%);
  }
}

.ellipsis {
  @include Roboto(16px, var(--main-gray), 400, 150%);
  text-align: center;
  margin-bottom: 16px;

  @include phone {
    margin-bottom: 8px;
  }
}

.ellipsis>p {
    @include Roboto(16px, var(--main-gray), 400, 150%);
}
