@import 'styles/common.scss';

.common_page_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  width: 100%;
  max-width: 1440px;
  padding: 0px 48px;
  margin-bottom: 99px;
  min-height: 100vh;
  @include phone() {
    padding: 0px 20px;
    margin-bottom: 0px;
    padding-bottom: 48px;
  }
}