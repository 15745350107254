@import 'styles/common.scss';

.info_modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  background: rgba(var(--neutral-1-rgb), 0.7);
  z-index: 9999;

  .info_modal_block {
    margin: auto;
    width: 728px;
    background: var(--main-white);
    position: relative;
    border-radius: 8px;
    flex-direction: column;

    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 40px;
      text-align: center;

      @include phone() {
        padding: 24px;
      }
    }

    .icon {
      width: 36px;
      height: 36px;

      margin-bottom: 10px;

      @include phone() {
        margin-bottom: 12px;
      }
    }

    h1 {
      @include Montserrat(32px, var(--text-accent-1), 500, 120%);
      letter-spacing: -0.02em;
      margin-bottom: 24px;

      @include phone() {
        @include Montserrat(24px, var(--text-accent-1), 500, 120%);
      }
    }

    &.success {
      h1 {
        color: var(--secondary-teal);
      }
    }

    &.error {
      h1 {
        color: var(--error-alert);
      }
    }

    &__body {
      & > p {
        @include Roboto(20px, var(--text-secondary), 400, 150%);

        @include phone() {
          @include Roboto(16px, var(--text-secondary), 400, 150%);
        }
      }

      span {
        color: var(--text-accent-1);
      }
    }

    &__footer {
      width: 100%;
      height: 88px;
      bottom: 0;
      border-top: 1px solid var(--shades-3);
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--shades-2);
      border-radius: 0 0 8px 8px;

      .button_wrapper .btn .children {
        @include Roboto(20px, var(--text-buttons-primary), 500, 120%);

        @include phone() {
          @include Roboto(16px, var(--text-buttons-primary), 500, 120%);
        }
      }

      .button_wrapper .btn.filled-gray .children {
        @include Roboto(20px, var(--text-buttons-secondary), 500, 120%);

        @include phone() {
          @include Roboto(16px, var(--text-buttons-secondary), 500, 120%);
        }
      }

      @include phone() {
        height: 62px;
      }
    }
  }
}
