@import 'styles/common.scss';
.login__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(var(--neutral-1-rgb), 0.7);
  z-index: 9999;

  .modal_block {
    margin: auto;
    width: 643px;
    background: var(--main-white);
    padding: 48px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    border-radius: 8px;

    @include phone() {
      padding: 24px;
      margin: auto 16px;
    }

    &__body {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      .login_page.email_password_form {
        margin-top: 0;

        .title p {
          @include Montserrat(28px, var(--main-dark-gray), 400, 120%);
  
          @include phone {
            @include Montserrat(18px, var(--main-dark-gray), 500, 120%);
          }
        }
      }

      .code_verification_form {
        &__text {
          margin-top: auto;
        }
      }

      .login_page {
        // width: 452px;
        // margin: auto;

        @include phone {
          width: 100%;
          margin: unset;
        }

        .title {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 32px;
          @include phone {
            margin-bottom: 26px;
          }
        }

        .entry {
          margin-bottom: 8px;

          &_item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &:nth-child(1) {
              margin-bottom: 32px;
              @include phone {
                margin-bottom: 16px;
              }
            }

            .title {
              @include Montserrat(24px, var(--text-primary1), 500, 120%);
              margin-bottom: 16px;
              @include phone {
                @include Montserrat(16px, var(--text-primary1), 500, 120%);
                margin-bottom: 8px;
              }
            }
          }
        }

        &__btn {
          display: flex;
          flex-direction: column;
          align-items: center;

          .link_wrapper {
            @include phone {
              a {
                @include Montserrat(16px, var(--main-blue), 500, 150%);
              }
            }
            a {
              align-self: flex-end;
              margin-bottom: 24px;
              width: 249px;
              text-align: center;
              @include phone {
                margin-bottom: 16px;
              }
            }
          }

          .auth_text {
            width:249px;
            text-align: center;
            p {
              @include Montserrat(18px, var(--main-blue), 500, 120%);
              @include phone {
                @include Montserrat(16px, var(--main-blue), 500, 120%);
              }
            }
            .link_wrapper{
              margin-bottom: 0px;
            }
          }

          .button_wrapper {
            margin-bottom: 32px;
            @include phone {
              margin-bottom: 24px;
            }
          }
        }
      }
    }

    .close_btn {
      position: absolute;
      top: 20px;
      right: 20.5px;

      cursor: pointer;

      @include phone {
        top: 8px;
        right: 8px;
      }

      &__icon {
        font-size: 32px;
        color: #676767;
      }
    }
  }
}
