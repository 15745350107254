@import 'styles/common.scss';

.additional_criteria_input {
  &__pair {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;

    .inputs {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      &>div:nth-child(1) {
        width: 40%;
      }
    }

    svg {
      width: 24px;
      height: 24px;

      transform: rotate(45deg);

      &:hover {
        cursor: pointer;
      }
    }
  }
  &__add_btn {
    .btn {
      background-color: var(--button-secondary) !important;
      @include Roboto(16px, var(--text-buttons-secondary), 500, 140%);
    }
  }
}
