.progress_bar_infinite {
  width: 100%;
  background-color: var(--shades-4);
  height: 3px;

  border-radius: 10px;

  overflow: hidden;

  &__line {
    background-color: var(--secondary-teal);
    height: 100%;

    width: 200px;

    border-radius: 10px;

    animation: move 1.5s infinite;

    @keyframes move {
      0% {
        margin-left: -100%;
      }

      100% {
        margin-left: 100%;
      }
    }
  }
}