@import 'styles/common.scss';

$black: #000;
$white: #fff;

.loader_container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 10px 0;

  &.popover-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes loader {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}

.loader {
  position: relative;
  animation: loader 2.5s infinite linear both;

  &.small {
    width: 24px;
    height: 24px;
  }

  &.normal {
    width: 40px;
    height: 40px;
  }

  &.large {
    width: 60px;
    height: 60px;
  }

  &-text {
    margin-top: 2rem;
    text-align: center;
    @include Montserrat(1.6rem, var(--main-dark-gray), 400, 120%);
    br {
      display: none;
    }
    @include phone {
      font-size: 20px;
      br {
        display: block;
      }
    }
  }

  &-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: loader-dot 2s infinite ease-in-out both;

    &:before {
      content: '';
      display: block;
      width: 25%;
      height: 25%;
      border-radius: 100%;
      animation: loader-dot-before 2s infinite ease-in-out both;

      .loader.black > & {
        background-color: $black;
      }

      .loader.white > & {
        background-color: $white;
      }

      .loader.main-gray > & {
        background-color: var(--main-gray);
      }
    }

    &:nth-child(1) {
      animation-delay: -1.1s;
    }
    &:nth-child(2) {
      animation-delay: -1s;
    }
    &:nth-child(3) {
      animation-delay: -0.9s;
    }
    &:nth-child(4) {
      animation-delay: -0.8s;
    }
    &:nth-child(5) {
      animation-delay: -0.7s;
    }
    &:nth-child(6) {
      animation-delay: -0.6s;
    }
    &:nth-child(1):before {
      animation-delay: -1.1s;
    }
    &:nth-child(2):before {
      animation-delay: -1s;
    }
    &:nth-child(3):before {
      animation-delay: -0.9s;
    }
    &:nth-child(4):before {
      animation-delay: -0.8s;
    }
    &:nth-child(5):before {
      animation-delay: -0.7s;
    }
    &:nth-child(6):before {
      animation-delay: -0.6s;
    }
  }
}
