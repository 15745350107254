@import 'styles/common.scss';

.lang_switch {
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &__btn {
    display: flex;
    align-items: center;
    gap: 6px;

    &_icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--shades-10);
      border-radius: 50%;

      svg {
        path {
          fill: var(--main-white);
        }
      }

      &.active {
        background-color: var(--shades-8);

        svg {
          path {
            fill: var(--shades-9);
          }
        }
      }

      @include phone() {
        width: 40px;
        height: 40px;
      }
    }
    &_text {
      height: 24px;
      @include Roboto(16px, var(--text-secondary), 500, 190%);
    }
  }

  &__arrow {
    &.active {
      transform: rotate(180deg);
    }
  }

  &__wrapper {
    background: var(--main-white);
    border: 1px solid var(--shades-2);
    box-shadow: 2px 2px 4px rgba(var(--neutral-1-rgb), 10%);
    border-radius: 4px;
    position: absolute;
    top: 56px;
    right: 0;

    div {
      width: 178px;
      padding: 8px 12px;
      border-bottom: 1px solid var(--shades-2);
      display: flex;
      gap: 6px;
      align-items: center;
      @include Roboto(16px, var(--main-gray), 400, 150%);
      letter-spacing: -0.01em;

      &:nth-last-child(1) {
        border: 0;
      }
    }

    &_arrow {
      font-size: 12px;
      margin-left: auto;
    }
  }
}