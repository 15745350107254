@import './common';
@import './theme.scss';
/** Roboto Normal **/
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf');
}
/** Roboto Medium **/
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/Roboto/Roboto-Medium.ttf');
}
/** Roboto Semi-Bold **/
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/Roboto/Roboto-Bold.ttf');
}
/** Roboto Bold **/
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/Roboto/Roboto-Bold.ttf');
}
/** Montserrat Normal **/
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf');
}
/** Montserrat Medium **/
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/Montserrat/Montserrat-Medium.ttf');
}
/** Montserrat Bold **/
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/Montserrat/Montserrat-Bold.ttf');
}
/** HelveticaNeue Normal **/
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/NeueHelvetica/NeueHelvetica-Regular.woff');
}
/** HelveticaNeue Medium **/
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/NeueHelvetica/NeueHelvetica-Medium.woff');
}
/** HelveticaNeue Bold **/
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/NeueHelvetica/NeueHelvetica-Bold.woff');
}

/** Gotham Book **/
@font-face {
  font-family: 'Gotham';
  font-weight: 400;
  font-style: normal;
  src:
    url('../assets/fonts/Gotham/GothamBook/font.woff2') format('woff2'),
    url('../assets/fonts/Gotham/GothamBook/font.woff') format('woff');
}
/** Gotham Medium **/
@font-face {
  font-family: 'Gotham';
  font-weight: 500;
  font-style: normal;
  src:
    url('../assets/fonts/Gotham/GothamMedium/font.woff2') format('woff2'),
    url('../assets/fonts/Gotham/GothamMedium/font.woff') format('woff');
}
/** Gotham Bold **/
@font-face {
  font-family: 'Gotham';
  font-weight: 700;
  font-style: normal;
  src:
    url('../assets/fonts/Gotham/GothamBold/font.woff2') format('woff2'),
    url('../assets/fonts/Gotham/GothamBold/font.woff') format('woff');
}
/** Gotham Light **/
@font-face {
  font-family: 'Gotham';
  font-weight: 200;
  font-style: normal;
  src:
    url('../assets/fonts/Gotham/GothamLight/font.woff2') format('woff2'),
    url('../assets/fonts/Gotham/GothamLight/font.woff') format('woff');
}
/** Gotham MediumItalic **/
@font-face {
  font-family: 'Gotham';
  font-weight: 500;
  font-style: italic;
  src:
    url('../assets/fonts/Gotham/GothamMediumItalic/font.woff2') format('woff2'),
    url('../assets/fonts/Gotham/GothamMediumItalic/font.woff') format('woff');
}

/** FuturaPTWebMedium **/
@font-face {
  font-family: 'FuturaPT';
  src:
    url('../assets/fonts/FuturaPT/futura-pt_medium.woff2') format('woff2'),
    url('../assets/fonts/FuturaPT/futura-pt_medium.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/Inter/Inter-Variable.ttf') format('truetype');
  font-variation-settings: 'wght' 400;
}

/** Inter Medium **/
@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/Inter/Inter-Variable.ttf') format('truetype');
  font-variation-settings: 'wght' 500;
}

/** Inter Bold **/
@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/Inter/Inter-Variable.ttf') format('truetype');
  font-variation-settings: 'wght' 700;
}

body {
  margin: 0;
  font-family: 'Inter', Montserrat, Roboto;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  display: flex;
  padding: 0 20px;
  width: 1342px;
  margin: auto;
  margin-top: 64px;
  margin-bottom: 40px;
  @include adaptive(1342) {
    width: 100%;
  }
  @include phone() {
    flex-direction: column;
    padding: 0;
    margin-top: 47px;
  }
}
.app {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  // position: relative;
  min-height: 100vh;
  &_wrapper {
    width: 100%;
    margin: 0 auto;
    margin-top: 64px;
    min-height: 100vh;
    &.auth {
      min-height: calc(100vh - 60px);
      display: flex;
      flex-direction: column;
      max-width: 1440px;
      margin: 0 auto;
      margin-top: 60px;
      padding: 0 48px;
      @include tablet() {
        position: relative;
      }
      @include phone() {
        padding: 0 24px;
        margin-top: 92px;
        min-height: calc(100vh - 92px);
      }
    }
  }
}
p {
  color: var(--main-dark-gray);
}
