@import 'styles/common.scss';

.text_area__field {
  position: relative;
  textarea {
    background-color: var(--shades-1);
    border: 2px solid var(--border-avatar);
    border-radius: 4px;
    padding: 13px 24px;
    width: 100%;
    min-height: 116px;
    @include Roboto(20px, var(--text-primary1), 400, 140%);
    position: relative;
    outline: none;

    &::placeholder {
      color: rgba($color: var(--text-secondary), $alpha: 0.6);
    }

    &:hover {
      border: 2px solid var(--border-field-hover);
    }

    @include phone() {
      padding: 10px 16px;
      @include Roboto(16px, var(--text-primary1), 400, 140%);
    }
  }

  .eye {
    position: absolute;
    right: 18px;
    top: 20px;
    width: 20px;
    height: 20px;
    &:hover {
      cursor: pointer;
    }
    @include phone {
      right: 16px;
      top: 16px;
    }
  }
  .error {
    &_icon {
      position: absolute;
      right: 18px;
      top: 20px;
      &:hover {
        cursor: pointer;
      }
      @include phone {
        right: 16px;
        top: 16px;
        width: 15px;
        height: 14px;
      }
    }
    &_text {
      @include Roboto(15px, var(--error-alert), 500, 150%);
      color: var(--error-alert);
    }
  }

  &.focus {
    input {
      color: var(--main-dark-gray);
      border: 2px solid var(--border-field-hover);
    }
  }
  &.error {
    input {
      border: 2px solid var(--error-alert);
    }
  }
}
