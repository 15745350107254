@import "../../../styles//common.scss";


.payment-calculator-main {
    border-radius: 8px;
    padding: 24px;
    background-color: var(--background-secondary);
    border: 1px solid var(--border-cards);
    width: 493px;
    height: 536px;
    padding: 26px 24px 32px 24px;

    @include phone {
        width: 327px;
        overflow: auto;
        padding: 16px;
    }

    & .payment-calculator-sub {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 30px;

        @include phone {
            gap: 12px;
            align-items: flex-start !important;
            margin-bottom: 16px;
        }

        & .payment-calculator-values {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 4px;

            @include phone {
                flex-direction: column;
                align-items: flex-start;
            }

            span {
                @include Roboto(16px, var(--text-primary1), 500, 140%);

                @include phone {
                    width: auto;
                    @include Roboto(14px, var(--text-primary1), 500, 140%);
                }
            }

            & .custom-input {
                width: 222px;
                height: 40px;
                padding: 13px 16px;
                border-radius: 4px;
                background: var(--background-primary-light);
                @include Roboto(16px, var(--text-primary1), 400, 140%);
                border: 1px solid var(--border-search-field);
                text-align: end;

                @include phone {
                    width: 295px;
                    height: 32px;
                    @include Roboto(14px, var(--text-primary1), 400, 140%);
                }

                &:focus {
                    outline: none;
                    border: 1px solid var(--border-field-hover);
                }
            }
        }

        & .payment-calculator-values-drop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2px;
            cursor: pointer;

            @include phone {
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                flex-direction: column;
                margin-bottom: 4px;
            }

            span {
                @include Roboto(16px, var(--text-primary1), 500, 140%);

                @include phone {
                    @include Roboto(14px, var(--text-primary1), 500, 140%);
                }

            }

            & .payment-calculator-payment-drop-div {
                position: relative;
                width: 222px;
                height: 40px;
                padding: 13px 16px;
                border-radius: 4px;
                display: flex;
                justify-content: end;
                align-items: center;
                background-color: var(--background-primary-light);
                gap: 16px;

                @include phone {
                    width: 295px !important;
                    height: 32px;
                    gap: 8px;
                }

                & .payment-calculator-payment-drop-div-value {
                    // margin-left: 36px;
                }

                & .payment-calculator-payment-dropdown {
                    position: absolute;
                    top: 43px;
                    left: 0;
                    width: 222px;
                    background-color: var(--background-primary-light);
                    border-radius: 6px;
                    z-index: 10;
                    border: 1px solid var(--border-field-hover);

                    @include phone {
                        width: 293px;
                        top: 34px;
                    }

                    & .payment-calculator-payment-dropdown-value {
                        display: flex;
                        align-items: center;
                        @include Roboto(14px, var(--text-primary1), 500, 140%);
                        padding: 10px 16px;
                        justify-content: space-between;
                        border-bottom: 1px solid var(--border-field-hover);

                        @include phone {
                            height: 32px;
                        }

                        &:nth-last-child(1) {
                            border-bottom: unset;
                        }
                    }
                }
            }
        }
    }

    & .payment-calculator-buttons {
        display: flex;
        gap: 23px;
        justify-content: center;
        align-items: center;

        @include phone {
            gap: 12px;
            flex-wrap: wrap;
            flex-direction: column-reverse;
        }

        button {
            height: 40px;
            width: 211px;
            padding: 8px 10px;
            border-radius: 4px;
            cursor: pointer;
            @include Roboto(16px, var(--text-buttons-primary), 500, 140%);
            background-color: var(--button-tetriary-active);

            @include phone {
                width: 295px;
                height: 32px;
                @include Roboto(14px, var(--text-buttons-primary), 500, 140%);
            }
        }

        .clear_btn .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 9px 10px;
            box-shadow: 0.995px 0.995px 0px 0px #E2EFEE;
        }

        & .button-disable {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}

.payment-calculator-horiz-main {
    display: flex;
    justify-content: center;

    @include phone {
        width: 100%;
    }

    & .payment-calculator-horiz {
        width: 100%;
        border: 1px solid var(--line-section-title-scroll);
        text-align: center;
    }
}

.payment-calculator-payment-drop-ml-0 {
    margin-left: 0;
}

.payment-calculator-payment-drop-ml-105 {
    margin-left: 105px;
}