@import "../../../../styles/common.scss";

.modal-container {
  background-color: rgba($color: var(--neutral-1-rgb), $alpha: 0.4);
  z-index: 999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  & .modal {
    background-color: var(--main-white);
    width: 70%;
    max-width: 728px;
    border-radius: 8px;
    position: relative;

    & .modal-close-icon {
      position: absolute;
      top: 10px;
      left: 10px;
      cursor: pointer;
      border-radius: 50%;
      overflow: hidden;
      z-index: 10;

      svg {
        width: 32px;
        height: 32px;
      }
    }

    & .modal-inner-container {
      margin: 40px;

      & .modal-inner-container-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        justify-content: center;

        @include phone {
          gap: 12px;
        }

        & .modal-header {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 24px;
        }

        & .modal-title {
          @include Montserrat(32px, var(--text-accent-1), 500, 140%);

          @include phone() {
            @include Montserrat(24px, var(--text-accent-1), 500, 140%);
          }

          margin-bottom: 24px;
          text-align: center;
        }

        & .modal-subtitle {
          position: relative;
        }

        & .modal-subtitle {
          @include Roboto(20px, var(--text-secondary), 400, 140%);
          margin-bottom: 16px;
          text-align: center;

          @include phone() {
            @include Roboto(16px, var(--text-secondary), 400, 140%);
          }
        }
      }

      & .modal-content,
      a {
        @include Roboto(20px, var(--main-gray), 400, 150%);
        text-align: center;
        position: relative;
      }

      & .custom_content {
        @include Roboto(20px, var(--text-secondary), 400, 120%);

        @include phone() {
          @include Roboto(16px, var(--text-secondary), 400, 120%);
        }
      }
    }

    & .modal-footer {
      background-color: var(--shades-2);

      &-error.enabled {
        margin-top: 10px;
        text-align: center;
      }

      &-buttons {
        display: flex;
        align-items: center;
        justify-content: center;

        gap: 80px;

        @include phone {
          gap: 10px;
          justify-content: space-around;
        }

        &.withError .modal-accept-button,
        &.withError .modal-close-button,
        &.withError .modal-decline-button {
          margin-top: 5px;
        }

        & .modal-accept-button,
        & .modal-close-button,
        & .modal-decline-button {
          margin-block: 20px;

          &-wrapper {
            position: relative;
          }

          /*button {
          & * {
            color: var(--main-white);
            margin: 14.5px 16px 15.5px 20px;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            font-family: 'Montserrat';
          }
        }*/
        }
      }

      & .modal-accept-button,
      & .modal-close-button,
      & .modal-decline-button {
        .btn .children {
          font-size: 20px;
          font-weight: 500;
          line-height: 120%;

          @include phone() {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@include tablet {
  .modal-container {
    align-items: flex-end;

    & .modal {
      width: 100vw;
      position: relative;

      & .modal-close-icon {
        position: absolute;
        top: 10px;
        right: 6px;
        left: auto;
        cursor: pointer;
        border-radius: 50%;
        overflow: hidden;

        svg {
          width: 32px;
          height: 32px;
        }
      }

      & .modal-phone-upper-line {
        position: absolute;
        top: 13px;
        left: calc(50vw - 32px);
        width: 64px;
        border-top: 4px solid #e0d9cd;
        border-radius: 60px;
        z-index: 999;
      }

      & .modal-inner-container {
        margin: 48px 24px 32px 24px;

        & .modal-title {
          font-size: 18px;
        }

        & .modal-content {
          font-size: 16px;
          border-radius: 10px 10px 0 0;
          overflow: hidden;
        }
      }

      & .modal-footer {

        & .modal-accept-button,
        & .modal-close-button,
        & .modal-decline-button {
          margin-block: 12px;

          button {
            // & * {
            //   margin: 10px 12px 12px 12px;
            // }

            .icon_right {
              margin: 0 12px 0 0;
            }

            .icon_right * {
              margin: 0 !important;
            }
          }

          &.right-0 {
            .children {
              margin-right: 0 !important;
            }
          }
        }
      }
    }
  }
}

@include phone {
  .modal-container {
    align-items: flex-end;

    & .modal {
      width: 100vw;
      position: relative;

      & .modal-close-icon {
        position: absolute;
        top: 10px;
        right: 6px;
        left: auto;
        cursor: pointer;
        border-radius: 50%;
        overflow: hidden;

        svg {
          width: 32px;
          height: 32px;
        }
      }

      & .modal-phone-upper-line {
        position: absolute;
        top: 13px;
        left: calc(50vw - 32px);
        width: 64px;
        border-top: 4px solid #e0d9cd;
        border-radius: 60px;
        z-index: 999;
      }

      & .modal-inner-container {
        margin: 48px 24px 32px 24px;

        & .modal-title {
          font-size: 18px;
        }

        & .modal-content {
          font-size: 16px;
          border-radius: 10px 10px 0 0;
          overflow: hidden;
        }
      }

      & .modal-footer {

        & .modal-accept-button,
        & .modal-close-button,
        & .modal-decline-button {
          margin-block: 11px;

          button {
            // & * {
            //   margin: 10px 12px 12px 12px;
            // }

            .icon_right {
              margin: 0 12px 0 0;
            }

            .icon_right * {
              margin: 0 !important;
            }
          }

          &.right-0 {
            .children {
              margin-right: 0 !important;
            }
          }
        }
      }
    }
  }
}

.read-more {
  cursor: pointer;
  @include Roboto(16px, var(--main-blue), 500, 150%);

  &:hover {
    text-decoration: underline;
  }
}