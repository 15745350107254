@import 'styles/common.scss';

.cookie {
  &_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    background: var(--shades-1);
    border-top: 2px solid var(--main-blue);
    gap: 10px;
    bottom: 0px;
    position: fixed;
    width: 100%;
    z-index: 999;

    @include phone() {
      border-top: none;
      flex-direction: column;
      padding: 0;
      border-radius: 8px;
      background: var(--main-white);
      height: 425px;
    }

    .cookie_btns {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      column-gap: 16px;

      @include phone {
        height: 86px;
        background: var(--shades-2);
        width: 100%;
        justify-content: center;
        align-items: center;
      }

      .button_wrapper {
        &.accept-btn .btn {
          @include Roboto(16px, var(--text-buttons-primary), 500, 140%)
        }

        &.decline-btn .btn {
          @include Roboto(16px, var(--text-buttons-secondary), 500, 140%)
        }
      }
    }

    .close_btn {
      &__icon {
        font-size: 32px;
        color: #676767;

        &:hover {
          cursor: pointer;
        }

        // @include phone() {
        //   display: none;
        // }
      }
    }
  }

  &_content {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-grow: 1;
    max-width: 1145px;
    @include Roboto(14px, var(--main-dark-gray), 400, 20px);

    .modal-phone-upper-line {
      position: absolute;
      top: 10px;
      left: calc(50vw - 32px);
      width: 64px;
      border-top: 2px solid #e0d9cd;
      border-radius: 60px;
    }

    @include phone() {
      height: 259px;
      flex-direction: column;
      text-align: center;
      margin: 48px 24px 32px 24px;
      @include Roboto(16px, var(--main-gray), 400, 150%);

      svg {
        margin-bottom: 24px;
      }
    }

    a {
      text-decoration: none;
      text-decoration: underline;
      @include Roboto(14px, var(--main-dark-gray), 600, 20px);

      @include phone() {
        @include Roboto(16px, var(--main-gray), 600, 150%);
      }
    }
  }
}

.modal-container .modal .modal-inner-container .modal-title.cookie_title {
  color: var(--secondary-6);
}