@import 'styles/common.scss';

.hasLicense_modal {
  &_title {
    @include Montserrat(
      32px !important,
      var(--text-primary1) !important,
      500 !important,
      130% !important
    );

    @include phone {
      @include Montserrat(
        24px !important,
        var(--text-primary1) !important,
        500 !important,
        140%
      );
    }
  }
  &_content {
    p {
      @include Roboto(
        20px !important,
        var(--text-secondary) !important,
        400 !important,
        140%
      );

      @include phone {
        @include Roboto(
          16px !important,
          var(--text-secondary) !important,
          400 !important,
          140%
        );
      }
    }
  }
  &_footer {
    border-top: 1px solid var(--line-footer);
    background-color: var(--background-secondary) !important;
    .modal-footer-buttons {
      gap: 16px !important;

      @include phone {
        justify-content: unset !important;
        padding: 0px 20px;
      }
    }
  }
  &__closeIcon {
    left: unset !important;
    right: 16px !important;
    top: 16px !important;

    @include phone {
      right: 6px !important;
      top: 6px !important;
    }
  }
  &__acceptButton,
  &__declineButton {
    .btn {
      border: 1px solid var(--border-tetriary-button);
      padding: 8px 16px !important;
    }
    @include phone {
      flex: 1;
      justify-content: center;
      .btn {
        width: 100%;
        height: unset !important;
      }
      .filled {
        border: none !important;
      }
    }
  }
}
