@import 'styles/common.scss';

.custom_cohort_modal_container {
  justify-content: center !important;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  .custom_modal {
    width: 648px !important;
    max-width: 648px !important;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding: 32px;
    @include phone {
      height: 100%;
      padding: 32px 16px;
    }

    .modal-close-icon {
      left: 16px !important;
      top: 16px !important;
      @media screen and (max-width: 647px) {
        left: unset !important;
        top: 12px !important;
        right: 20px !important;
      }
    }

    .custom_modal_inner_container {
      margin: 0px !important;
      overflow: auto;
      overflow-x: hidden;
      max-height: calc(100% - 102px);
      background-color: var(--background-primary-light);

      @media screen and (max-width: 647px) {
        max-height: calc(100% - 140px);
      }

      .modal-inner-container-header {
        padding: 0px 60px;
        @include phone {
          padding: 0px 20px;
        }
      }

      .custom_modal_header {
        margin-bottom: 0px !important;
      }
      .custom_modal_title {
        width: 100%;
        margin-bottom: 0px !important;
        color: var(--text-primary2) !important;
      }
      .modal-subtitle {
        margin-bottom: 0px !important;

        .ellipsis {
          margin-bottom: 0px !important;
          @include Roboto(16px, var(--text-secondary), 400, 140%);
        }
        .ellipsis > p {
          @include Roboto(16px, var(--text-secondary), 400, 140%);
        }
        .read_more,
        .read_less {
          margin-left: 8px;
          text-decoration: underline;
          @include Roboto(16px, var(--text-accent-1), 700, 140%);
        }
      }
      .custom_modal_content {
        margin-top: 32px;
      }
      .custom_modal_content_2 {
        margin-top: 32px;
      }
    }

    .custom_modal_inner_container::-webkit-scrollbar {
      width: 6px;
    }

    .custom_modal_inner_container::-webkit-scrollbar-thumb {
      background: var(--line-section-title-scroll);
      border-radius: 10px;
    }

    .custom_modal_footer_center,
    .custom_modal_footer {
      position: absolute;
      bottom: 32px;
      width: calc(100% - 64px - 6px);
      border-radius: 0px 0px 8px 8px;
      border-right: 2px solid var(--border-funding);
      border-bottom: 2px solid var(--border-funding);
      border-left: 2px solid var(--border-funding);
      border-top: 2px solid var(--border-funding);

      @include phone {
        width: calc(100% - 32px - 6px);
      }

      .modal-footer-buttons {
        gap: 16px !important;
        background-color: var(--background-primary-light);
        padding: 32px 40px;
        justify-content: space-between;
        align-items: center;
        border-radius: 0 0 8px 8px;
        @media screen and (max-width: 647px) {
          flex-direction: column-reverse;
          padding: 24px 16px;
        }

        .button_wrapper {
          margin-block: 0px !important;

          @media screen and (max-width: 647px) {
            width: 100%;
          }
        }

        .custom_modal_acceptButton {
          .btn {
            height: 38px;
            width: 246px;
            background: var(--button-primary1-active) !important;
            margin: 0px !important;
            padding: 8px 10px;
            @media screen and (max-width: 647px) {
              width: 100%;
            }
            .children {
              @include Roboto(16px, var(--text-buttons), 500, 140%);
            }
          }
        }

        .custom_modal_declinedButton {
          .btn {
            height: 38px;
            width: 246px;
            background: var(--button-secondary) !important;
            margin: 0px !important;
            padding: 8px 10px;
            box-shadow: none;
            @media screen and (max-width: 647px) {
              width: 100%;
            }
            .children {
              @include Roboto(16px, var(--text-buttons-secondary), 500, 140%);
            }
          }
        }
      }
    }

    .custom_modal_footer_center {
      .modal-footer-buttons {
        justify-content: center !important;
      }
    }
  }
}
