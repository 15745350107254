@import 'styles/common.scss';

.list_with_selectables {
  width: 100%;
  margin-bottom: 0;
  position: relative;

  @include phone() {
    margin-bottom: 24px;
  }

  &__list {
    column-count: 2;
    column-gap: 32px;

    &.one-column {
      column-count: 1;
    }

    &.fillRowsFirst {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &>div {
      margin-bottom: 8px;
    }

    @include phone() {
      column-count: 1;
      display: contents;
    }
  }
}