@import 'styles/common.scss';

.field_wrapper {
  margin-bottom: 42px;
  width: 100%;
  text-align: left;
  @include phone() {
    margin-bottom: 24px;
  }

  &_key {
    margin-bottom: 18px;

    &__title {
      @include Montserrat(24px, var(--text-buttons-secondary), 500, 140%);

      @include phone() {
        @include Montserrat(18px, var(--text-buttons-secondary), 500, 140%);
      }
    }

    &__subtitle {
      @include Roboto(20px, var(--text-secondary), 400, 140%);
      margin-top: 4px;

      @include phone() {
        @include Roboto(16px, var(--text-secondary), 400, 140%);
      }
    }
  }
}
