@import 'styles/common.scss';

.footer_wrapper__home {
  width: 100%;

  @include phone {
    width: unset;
  }

  &.hidden {
    @include phone() {
      bottom: 0;
      left: 0;
      width: 100%;

      .footer_wrapper__home_desktop {
        display: none;
      }
    }
  }

  &_desktop {
    width: 100%;
    // bottom: 47px;
    padding-left: 48px;
    padding-right: 48px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    gap: 16px;

    @include phone() {
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;
      gap: 32px;
      padding: 0px;
    }

    .poweredBy {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 119.13px;
      height: 44px;
      gap: 4px;

      p {
        @include Roboto(14px, var(--text-secondary), 400, 140%);
      }
    }

    p {
      @include Roboto(14px, var(--text-secondary), 400, 140%);
      a {
        @include Roboto(14px, var(--text-secondary), 400, 140%);
        text-decoration: none;
      }    
    }

    p:nth-of-type(2) {
      text-align: right;
    }
  }
}