@import 'styles/common.scss';

.advisor_referral_decline_modal {
  width: unset !important;
  overflow: hidden;

  .modal-inner-container-header {
    @include phone {
      gap: unset !important;
    }
  }

  &__header {
    margin-bottom: 12px !important;
  }

  &__inner_container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 70vh;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--line-section-title-scroll);
      border-radius: 10px;
    }
  }
  &__title {
    @include Roboto(36px, var(--text-primary1), 450, 115%);
    margin-bottom: 40px !important;
    font-size: 36px;
    font-weight: 450 !important;
    width: 70%;

    @include phone {
      width: unset;
      @include Roboto(24px, var(--text-primary1), 450, 100%);
      margin-bottom: 10px !important;
      font-weight: 450 !important;
    }
  }

  &__modalContent {
    padding: 1px 2px;
    .list_with_selectables__list {
      height: unset !important;
      display: flex;
      flex-direction: column;
      .select_button {
        margin-bottom: 24px !important;
        @include phone {
          margin-bottom: 16px !important;
        }
        .select_button__text {
          text-wrap: auto;
          text-align: left;
        }
      }
    }
  }

  &__footer {
    background-color: var(--background-secondary);
    .modal-footer-buttons {
      flex-direction: row-reverse;
      justify-content: space-around !important;
      height: 88px !important;
      @include phone {
        height: 72px !important;
      }
    }
    &__acceptButton .btn {
      background-color: var(--button-primary1-active) !important;
      border: 1px solid var(--border-tetriary-button) !important;
      width: 140px;
      height: 48px;
      padding: 8px 16px;
      @include phone {
        width: 120px;
        height: 40px;
        padding: 8px 16px;
      }
      .children {
        color: var(--text-on-the-dark-background) !important;
        @include Roboto(20px, var(--text-on-the-dark-background), 500, 140%);
        @include phone() {
          @include Roboto(14px, var(--text-on-the-dark-background), 500, 150%);
        }
      }
    }
    &__closeButton .btn {
      background: var(--button-tetriary-active) !important;
      border: 1px solid var(--border-tetriary-button) !important;
      width: 140px;
      height: 48px;
      padding: 8px 16px;
      @include phone {
        width: 120px;
        height: 40px;
        padding: 8px 16px;
      }
      .children {
        @include Roboto(20px, var(--text-buttons-secondary), 500, 150%);
        @include phone() {
          @include Roboto(14px, var(--text-buttons-secondary), 500, 150%);
        }
      }
    }
  }

  &__closeIcon {
    left: unset !important;
    right: 10px;
  }

  .step2_description {
    @include Roboto(20px, var(--text-secondary), 400, 140%);

    @include phone {
      @include Roboto(14px, var(--text-secondary), 400, 160%);
    }
  }
}
