@import 'styles/common.scss';

.tags_container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;

  .tag_item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 4px;
    height: 36px;
    padding:9px 8px;
    background: var(--button-tetriary-active);
    border-radius: 4px;
    border: 1px solid var(--border-tetriary-button);
    text-wrap: nowrap;
    min-width: 30px;
    box-shadow: 0.995px 0.995px 0px 0px #E2EFEE;

    span {
      text-wrap: nowrap;
      @include Roboto(14px, var(--text-primary1), 400, 140%);
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .floating_tags_container {
      position: absolute;
      top: 38px;
      right: 0px;
      display: flex;
      gap: 8px;
      flex-direction: column;
      background-color: var(--background-primary-light);
      border-radius: 4px;
      border: 1px solid var(--border-tetriary-button);
      box-shadow: 0.995px 0.995px 0px 0px #E2EFEE;
      align-items: start;
      padding: 12px;

      .floating_tag_item {
        display: flex;
        align-items: center;
        white-space: nowrap;

        span {
          @include Roboto(14px, var(--text-primary1), 400, 140%);
          background: var(--main-white);
          // border-bottom: 1px solid var(--v2-main-color-new-cyan-shade-03);
          border-radius: 4px;
          padding: 3.5px 6px;
          text-wrap: nowrap;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  #float {
    cursor: pointer;

    &>span {
      @include Roboto(13px, var(--main-blue), 700, 120%);
      text-wrap: nowrap;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}