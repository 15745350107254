@import 'styles/common.scss';

.advisor_referral_modal {
  width: 600px !important;
  // max-height: 85%;
  overflow: hidden;

  &__header {
    margin-bottom: 8px !important;
  }
  &__title {
    @include Montserrat(36px, var(--text-primary1), 450, 115%);
    margin-bottom: 32px !important;
  }

  &__inner_container {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 40px 44px 32px 44px !important;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--line-section-title-scroll);
      border-radius: 10px;
    }

    .profile_field {
      margin-bottom: 32px;
      .profile_field__title {
        margin-bottom: 8px;
      }
      .profile_field__subtitle {
        margin-bottom: 12px;
      }
      .drop_down_container__text_field {
        text-align: left;
      }
    }
  }

  &__closeIcon {
    left: unset !important;
    right: 16px !important;
    top: 16px !important;
  }
  &__footer {
    background-color: var(--background-secondary);
    .modal-footer-buttons {
      gap: 16px !important;
      @include phone {
        justify-content: center;
        gap: 24px !important;
        height: 72px;
      }

      &__acceptButton .btn {
        background-color: var(--button-primary1-active);
        border: 1px solid var(--border-tetriary-button);
        width: 140px;
        height: 48px;
        padding: 8px 16px;
        @include phone {
          width: 120px;
          height: 40px;
          padding: 8px 16px;
        }
        .children {
          color: var(--text-on-the-dark-background) !important;
          @include Roboto(20px, var(--text-on-the-dark-background), 500, 140%);
          @include phone() {
            @include Roboto(
              14px,
              var(--text-on-the-dark-background),
              500,
              150%
            );
          }
        }
      }
      &__declineButton .btn {
        background-color: var(--button-tetriary-active);
        border: 1px solid var(--border-tetriary-button);
        width: 140px;
        height: 48px;
        padding: 8px 16px;
        @include phone {
          width: 120px;
          height: 40px;
          padding: 8px 16px;
        }
        .children {
          @include Roboto(20px, var(--text-buttons-secondary), 500, 150%);
          @include phone() {
            @include Roboto(14px, var(--text-buttons-secondary), 500, 150%);
          }
        }
      }
    }
  }
}
