@import 'styles/common.scss';

.profile_field {
  width: 100%;

  @include phone() {
    margin-bottom: 24px;
  }

  span {
    color: var(--main-gray);
  }

  &__title {
    margin-bottom: 16px;
    text-align: left;
    @include Montserrat(24px, var(--text-primary1), 500, 140%);

    @include phone() {
      @include Montserrat(18px, var(--text-primary1), 500, 140%);
    }
  }

  .check_list {
    position: relative;
    text-align: left;
  }
}