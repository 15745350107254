@import 'styles/common.scss';

.date_picker_field {
  position: relative;
  width: 100%;
  input {
    background-color: var(--shades-1);
    border: 2px solid var(--border-avatar);
    border-radius: 4px;
    padding: 13px 24px;
    width: 100%;
    @include Roboto(20px, var(--main-gray), 400, 150%);
    position: relative;
    outline: none;
    &::placeholder {
      color: rgba($color: var(--main-gray), $alpha: 0.6);
    }

    &:hover {
      border: 2px solid var(--border-field-hover);
    }

    @include phone() {
      padding: 10px 16px;
      @include Roboto(16px, var(--main-gray), 400, 150%);
    }
  }


  &.focus {
    input {
      color: var(--main-dark-gray);
      border: 2px solid var(--border-field-hover);
    }
  }

  &__icon {
    z-index: 10;
    width: unset;
    height: unset;
    right: 24px;
    top: 9px;
    padding: unset;
    cursor: pointer;
  }
}
