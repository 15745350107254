@import 'styles/common.scss';

.button_wrapper {
  .btn {
    white-space: nowrap;

    &:disabled {
      opacity: 0.4;

      &:hover {
        cursor: not-allowed !important;
      }
    }

    &.loading {
      position: relative;

      .loader {
        position: absolute;
      }

      .icon_left {
        visibility: hidden;
      }

      .icon_right {
        visibility: hidden;
      }

      .children {
        visibility: hidden;
      }
    }
  }

  &__file {
    position: relative;

    &>label {
      &:hover {
        cursor: pointer;
      }
    }

    &>input {
      position: absolute;
      width: 1%;
      height: 1%;
      top: -1px;
      opacity: 0;
    }
  }

  .link {
    white-space: nowrap;
    text-decoration: none;
  }

  .external_link {
    white-space: nowrap;
    text-decoration: none;
  }

  //general button properties
  .filled {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    background: var(--button-primary1-active);
    border-radius: 4px;

    border: 1.5px solid var(--button-primary1-active);

    @include Roboto(16px, var(--text-buttons-primary), 500, 140%);

    &:hover {
      cursor: pointer;
    }
  }

  .filled-gray {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    background: var(--button-tetriary-active);
    border-radius: 4px;

    border: 1px solid var(--border-tetriary-button);

    box-shadow: 0.995px 0.995px 0px 0px var(--border-dropdown-filters);

    @include Roboto(16px, var(--text-buttons-secondary), 500, 140%);

    &:hover {
      cursor: pointer;
    }
  }

  a {
    &.filled {
      text-decoration: none;
    }
  }

  .text,
  .text-underline {
    @include Montserrat(18px, var(--main-blue), 500, 150%);

    &:hover {
      cursor: pointer;
    }
  }

  .text-underline {
    text-decoration: underline;
  }

  &.button_wrapper__icon {
    .btn {
      gap: 6px;
    }
  }

  &.small {
    .filled {
      border: 1px solid var(--shades-12);
    }
  }

  // color for medium and large filled buttons
  &.medium,
  &.large {
    .filled {
      color: var(--text-buttons-primary);
      border: none;
      background: var(--button-primary1-active);
    }
  }

  //height and padding
  &.small {
    .filled {
      height: 32px;
      padding: 8.5px 8px 9.5px;

      @include phone() {
        height: 34px;
        padding: 9px 8px;
      }
    }

    .filled-gray {
      height: 32px;
      padding: 8.5px 8px 9.5px;

      @include phone() {
        height: 34px;
        padding: 9px 8px;
      }
    }

    &.icon {
      .filled {
        height: 36px;
        padding: 8px;

        @include phone() {
          height: 32px;
        }
      }

      .filled-gray {
        height: 36px;
        padding: 8px;

        @include phone() {
          height: 32px;
        }
      }
    }
  }

  &.medium {
    .filled {
      padding: 10px 12px 12px;
      height: 36px;

      @include phone() {
        height: 38px;
      }
    }

    .filled-gray {
      padding: 8px 16px;
      height: 36px;

      @include phone() {
        height: 38px;
      }
    }

    &.icon {
      .filled {
        padding: 9.5px 12px 10.5px;
        height: 40px;

        @include phone() {
          height: 36px;
        }
      }

      .filled-gray {
        padding: 9.5px 12px 10.5px;
        height: 40px;

        @include phone() {
          height: 36px;
        }
      }
    }
  }

  &.large {
    .filled {
      padding: 8px 16px;
      height: 44px;

      @include phone() {
        height: 46px;
      }
    }

    .filled-gray {
      padding: 9px 16px;
      height: 44px;

      @include phone() {
        height: 46px;
      }
    }

    &.icon {
      .filled {
        padding: 13.5px 16px 14.5px 20px;
        height: 48px;

        @include phone() {
          height: 44px;
        }
      }

      .filled-gray {
        padding: 13.5px 16px 14.5px 20px;
        height: 48px;

        @include phone() {
          height: 44px;
        }
      }
    }
  }
}