@import 'styles/common.scss';

.funding_modal {
  max-width: 728px !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;

  @include phone {
    width: 100vh;
  }

  &_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px !important;
    padding: 40px;
    overflow: auto;

    @include phone {
      padding: 48px 24px 32px 24px !important;
      display: block;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--shades-4);
      border-radius: 10px;
    }

    .funding_modal_header {
      margin-bottom: unset !important;

      .sb-avatar__text {
        border-radius: 100%;
        border: 1px solid var(--border-avatar);
      }
    }

    .funding_modal_content {
      flex-grow: 1;

      .description {
        height: 60px;
        width: 100%;
        margin: 12px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        @include Roboto(20px, var(--main-dark-gray), 400, 150%);

        @include phone {
          @include Roboto(16px, var(--main-dark-gray), 400, 150%);
        }
      }

      .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        p {
          @include Roboto(16px, var(--text-secondary), 500, 140%);
          text-align: center;

          @include phone {
            @include Roboto(14px, var(--text-secondary), 500, 140%);
          }
        }

        .contact {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 16px;

          @include phone {
            flex-direction: column;
          }

          &_email,
          &_mobile {
            border-radius: 4px;
            padding: 9px 20px;
            background: var(--tag-secondary-background);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            @include Roboto(16px, var(--text-secondary), 500, 140%);

            @include phone {
              width: 100%;
            }
          }
        }
      }

      .more_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 32px;
        width: 100%;
        gap: 16px;

        p {
          @include Roboto(16px, var(--text-secondary), 500, 140%);
          text-align: center;

          @include phone {
            @include Roboto(14px, var(--text-secondary), 500, 140%);
          }
        }

        .website {
          .button_wrapper a {
            border-radius: 4px;
            padding: 9px 20px;
            background: var(--tag-secondary-background);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 8px;
            @include Roboto(16px, var(--text-secondary), 500, 140%);
          }
        }
      }
    }
  }

  &_footer {
    .btn {
      padding: 8px 16px !important;
      width: 140px;
    }
  }

  &_header {
    .avatar__img .sb-avatar img {
      border: 1px solid var(--border-avatar);
    }
  }

  &_title {
    margin-bottom: 40px !important;
    @include Montserrat(24px !important, var(--text-primary1) !important, 500, 140%);

    @include phone {
      margin-bottom: 32px !important;
      @include Montserrat(18px !important, var(--text-primary1) !important, 500, 140%);
    }
  }

  &__closeIcon {
    left: unset !important;
    right: 16px !important;
    top: 16px !important;

    @include phone {
      right: 6px !important;
      top: 6px !important;
    }
  }
}