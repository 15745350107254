@import "../../../../styles/common.scss";

.calculator_modal_container {
  background-color: rgba($color: var(--neutral-1-rgb), $alpha: 0.4);
  z-index: 999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  & .calculator_modal {
    background-color: var(--background-primary-light);
    border-radius: 8px;
    width: fit-content;
    height: fit-content;
    position: relative;

    @include tablet {
      width: fit-content;
      height: fit-content;
    }

    @include phone {
      width: fit-content;
    }
   .modal-content{
    border-radius: unset !important;
   }
    @include phone{
      .modal-phone-upper-line {
        position: absolute;
        top: 13px;
        left: calc(50vw - 64px);
        width: 64px;
        border-top: 4px solid #e0d9cd;
        border-radius: 60px;
        z-index: 999;
    }
    }


    .modal-close-icon {
      top: 16px;
      right: 16px;
      left: auto;
      @include phone{
        top: 8px;
        right: 8px;
      }
    }
    .modal-phone-upper-line{
      display: none;
    }
  }

  & .calculator_modal_inner_container {
    margin: 40px;

    @include phone{
      margin:32px 8px !important;
  }

    & .calculator_modal_title {
      color: #103049 !important;
      margin-bottom: 24px;
      text-align: center;
      @include phone {
        font-size: 20px !important;
        margin-bottom: 16px !important;

      }
    }
  }
}