@import 'styles/common.scss';

.reset_password_form {
  // max-width: 532px;
  width: 100%;
  // margin: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @include phone() {
    width: 100%;
    padding: 0px;
    margin: 0px;
  }

  &__body {
    width: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include phone() {
      padding: 40px 24px 32px 24px;
    }

    &__head {
      margin-bottom: 40px;
      text-align: center;

      // to avoid expanding form when an error is shown
      height: calc(32px * 1.2); // size of the __title part

      @include phone() {
        margin-bottom: 23px;

        height: calc(24px * 1.2); // size of the __title part for mobile
      }

      &__title {
        @include Montserrat(32px, var(--text-primary1), 500, 120%);

        @include phone() {
          @include Montserrat(24px, var(--text-primary1), 500, 120%);
        }
      }

      &__error {
        margin-top: 5px;
        @include Roboto(18px, var(--main-gray), 500, 120%);
        color: var(--error-alert);
      }
    }

    &__field_title {
      margin-right: auto;

      @include Montserrat(24px, var(--text-primary1), 500, 120%);

      @include phone() {
        @include Montserrat(16px, var(--text-primary1), 500, 120%);
      }
    }

    &__field_subtitle {
      margin-right: auto;

      @include Roboto(20px, var(--main-gray), 400, 150%);

      @include phone() {
        @include Roboto(18px, var(--main-gray), 400, 150%);
      }
    }

    .input_entry_field {
      &.current-password {
        margin-top: 16px;
        margin-bottom: 40px;

        @include phone() {
          margin-top: 12px;
          margin-bottom: 23px;
        }
      }

      &.new-password {
        margin-top: 16px;
        margin-bottom: 8px;

        @include phone() {
          margin-top: 12px;
        }
      }

      &.password-confirmation {
        margin-bottom: 8px;
      }
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: center;
    .button_wrapper .btn .children {
      @include Roboto(20px, var(--text-buttons-primary), 500, 120%);
      @include phone() {
        @include Roboto(16px, var(--text-buttons-primary), 500, 120%);
      }
    }
  }

  &__profile_footer {
    width: 100%;
    height: 88px;
    border-top: 1px solid var(--shades-3);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--shades-2);
    gap: 16px;
    border-radius: 0px 0px 8px 8px;

    @include phone() {
      height: 62px;
      justify-content: space-between;
      padding: 0 12px;
    }
  }

  &.login_page {
    .reset_password_form__body {
      &__head {
        // to avoid expanding form when an error is shown
        height: calc(28px * 1.2); // size of the __title part

        &__title {
          @include Montserrat(28px, var(--main-dark-gray), 400, 120%);

          @include phone() {
            @include Montserrat(24px, var(--main-dark-gray), 400, 120%);
          }
        }

        &__error {
          @include Roboto(16px, var(--main-gray), 500, 120%);
        }
      }

      &__field_title {
        @include Montserrat(20px, var(--main-dark-gray), 500, 120%);
      }
    }
  }
}

.custom_content {
  width: 75%;
  margin: 0 auto;
}