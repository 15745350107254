@import 'styles/common.scss';

.home_tabs {
  display: flex;
  justify-content: space-between;
  gap: 35px;

  @include tablet() {
    display: none;
  }

  &.in-modal {
    @include tablet() {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      gap: 0px;
    }
  }

  &_item {
    padding: 5px 7px;
    text-decoration: none;
    margin: auto;

    @include tablet() {
      padding: 25px 0px;
      border-bottom: 1px solid var(--border-dropdown-filters);
    }

    @include phone() {
      padding: 25px 0px;
      border-bottom: 1px solid var(--border-dropdown-filters);
    }

    &__text {
      @include Roboto(16px, var(--text-primary1), 400, 140%);
      border-bottom: 1.14px solid transparent;

      @include phone() {
        @include Roboto(20px, var(--text-primary1), 400, 140%);
      }
    }

    // &__icon {
    //   color: var(--shades-8);
    // }

    &:hover {
      cursor: pointer;
    }

    &.active {

      .home_tabs_item__icon,
      .home_tabs_item__text {
        @include Roboto(16px, var(--text-primary1), 700, 140%);
        border-bottom: 1.14px solid var(--line-header);

        @include tablet() {
          border-bottom: none;
          @include Roboto (20px, var(--text-primary1), 700, 140%);
        }

        @include phone() {
          border-bottom: none;
          @include Roboto (20px, var(--text-primary1), 700, 140%);
        }
      }
    }

    &__text:hover {
      border-bottom: 1px solid var(--line-section-title-scroll);
    }
  }

  &.with-dividers {
    display: flex;
    align-items: center;
    gap: 0px;

    @include tablet() {
      flex-direction: initial;
      justify-content: initial;
      text-align: left;
    }

    @include phone() {
      flex-direction: initial;
      justify-content: initial;
      text-align: left;
    }

    a {
      padding-block: 0;
      border-bottom: none;

      &.active>div {
        text-align: right;
        @include Roboto(16px, var(--text-primary1), 700, 140%);
      }

      div {
        padding-block: 0 !important;
        text-align: right;
        @include Roboto(16px, var(--text-primary1), 400, 140%);
        padding-block: 7px;
      }
    }

    .tabs-horizontal-divider {
      height: 18px;
      width: 1px;
      background: var(--text-primary1);
      margin: 0 10px;

      @include phone {
        width: 2px;
        margin: 0 10px;
      }
    }
  }
}