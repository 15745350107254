@import 'styles/common.scss';

.modal-container.video_preview {
  @include phone() {
    align-items: center;
  }

  .modal {
    width: unset;
    max-width: 90%;
    position: relative;
    &-inner-container {
      margin: 0px;
    }
  }
  .video_preview__close {
    position: absolute;
    transform: rotate(45deg);
    top: 24px;
    right: 24px;
    width: 26px;
    height: 26px;
    z-index: 1;
    @include phone() {
      top: 10px;
      right: 10px;
    }

    &:hover {
      cursor: pointer;
    }
    path {
      fill: var(--main-white);
    }
  }
}
