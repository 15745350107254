@import "../../../../styles/common.scss";

.profile_missing_data_modal {
  max-width: 630px !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  @include phone {
    width: 100vh;
  }

  &_phone-upper-line {
    display: none;
  }

  &-description {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    color: var(--main-gray);
    margin-bottom: 24px;

    @include phone {
      font-size: 16px;
    }
  }

  &_title {
    margin: 40px;
    @include phone {
      margin: 20px;
    }
    white-space: pre-line;
  }

  &_container {
    height: 100%;
    display: flex;
    margin: 0 !important;
    flex-direction: column;

    .profile_missing_data_modal_header {
      margin-bottom: 10px !important;
    }
    .profile_missing_data_modal_content {
      max-height: 55vh;
      overflow-y: scroll;
      flex-grow: 1;
      @include phone {
        padding: 0 18px 36px 18px;
      }
      padding: 0 40px 40px 40px;
    }
  }
  &_footer {
    //height: 88px !important;

    .modal-close-button {
      background: var(--shades-9) !important;

      &-wrapper {
        .error_cloud {
          border-color: var(--shades-5);
          top: -33px;
          &__text {
            white-space: nowrap;
            color: var(--main-dark-gray);
            @include phone {
              white-space: pre-wrap;
            }
          }

          @include phone {
            width: 155px;
            top: -61px;
          }
          &__triangle {
            & > div {
              border-color: var(--shades-5);
            }
            & > div:nth-child(2) {
              border-color: white;
            }
          }
        }
      }
    }
  }
}
