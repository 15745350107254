@import "../../../styles/common.scss";

.errors_text {
  color: var(--error-alert);

  &__bullet_list {
    padding-left: 20px;
  }

  // is outside bullet list because text might be shown without bullet list
  &__bullet_list__text {
    @include Roboto(15px, var(--error-alert), 500, 150%);
  }
}