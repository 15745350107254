@import 'styles/common.scss';

.drop_down_container {
  position: relative;

  &__text_field {
    position: relative;
    background-color: var(--shades-1);
    border: 2px solid var(--border-avatar);
    border-radius: 4px;
    padding: 13px 24px;
    width: 100%;

    p {
      @include Roboto(20px, var(--text-primary1), 400, 140%);
      opacity: 0.6;

      @include phone() {
        @include Roboto(16px, var(--text-primary1), 400, 140%);
      }
    }

    .value {
      opacity: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 97%;
    }

    .arrow {
      position: absolute;
      right: 22px;
      top: 45%;
      width: 12px;
      height: 7px;
      transform: rotate(0deg);
      transition: transform 0.5s;

      @include phone() {
        right: 10px;
      }

      path {
        fill: var(--main-dark-gray);
      }

      &.active {
        transform: rotate(180deg);
        transition: transform 0.5s;
      }
    }

    &.error {
      border: 2px solid var(--error-alert);
    }

    &:hover {
      border: 2px solid var(--border-field-hover);
      cursor: pointer;
    }

    &.active {
      color: var(--main-dark-gray);
      border: 2px solid var(--border-field-hover);
    }

    .upward {
      top: auto;
      bottom: 60px;
    }
  }

  &__list {
    margin-top: 10px;
    box-shadow: inset 0px -1px 0px #e3dccf;
    width: 100%;
    border: 2px solid var(--shades-6);
    z-index: 1;
    background: var(--main-white);
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    max-height: 42vh;
    overflow: auto;
    z-index: 2;
    @include Roboto(20px, var(--main-gray), 400, 150%);

    &:focus {
      color: rgba($color: var(--main-gray), $alpha: 0.6);
    }

    @include phone() {
      @include Roboto(16px, var(--main-gray), 400, 150%);
    }

    .select input[name='list']:not(:checked)~#text {
      color: black;
      background: var(--main-white);
    }

    .select input[name='list']:checked~#text {
      background: transparent;
      color: transparent;
      z-index: 2;
    }

    &__item {
      width: 100%;
      border-top: 2px solid var(--shades-6);
      padding: 13px 24px;
      @include Roboto(13px, var(--main-dark-gray), 500, 120%);

      @include phone() {
        padding: 10px 16px;
      }

      &:nth-child(1) {
        border: 0;
      }

      &:hover {
        cursor: pointer;
        background: var(--shades-2);
      }
    }
  }
}