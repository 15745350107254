:root {
  --secondary-teal: #009999;
  --secondary-dark-blue: #011b31;
  --secondary-4: #780008;
  --secondary-5: #ba2c2b;
  --secondary-6: #1b7084;
  --secondary-8: #712885;

  --main-dark-gray: #2b2b2b;
  --main-gray: #676767;
  --main-gray-rgb: 103, 103, 103;
  --main-blue: #053b66;
  --main-yellow: #fab019;
  --main-white: #ffffff;

  --shades-1: #fafaf9;
  --shades-2: #f3f2f0;
  --shades-3: #edebe7;
  --shades-4: #d9d9d9;
  --shades-5: #a7a9ac;
  --shades-6: #e0d9cd;
  --shades-7: #aca392;
  --shades-8: #d2ebff;
  --shades-9: #023055;
  --shades-10: #145386;
  --shades-11: #565252;
  --shades-12: #457ca8;
  --shades-13: #edeff3;

  --neutral-1: #000000;
  --neutral-1-rgb: 0, 0, 0;
  --neutral-2: #1e1e1e;
  --neutral-3: #353535;
  --neutral-4: #4e4e4e;
  --neutral-5: #696969;
  --neutral-6: #858585;
  --neutral-7: #dddddd;
  --neutral-8: #f1f1f1;
  --neutral-9: #f8f8f8;
  --neutral-10: #ffffff;

  // *************************** New Design Palette ************************************
  // Background
  --background-primary-light: #ffffff;
  --background-primary-dark: #053b66;
  --background-secondary: #f5f6f7;
  --background-section: #fff6e4;
  --background-avatar-with-letters: #459396;
  --background-avatar-organization: #f5f6f7;
  --background-glossary: #e5f2f3;
  --background-icon: #fcbf4a;
  --background-card: #f0f8ff;

  // Border
  --border-cards: #cdd8e0;
  --border-dropdown-filters: #e5f2f3;
  --border-tetriary-button: #e5f2f3;
  --border-search-field: #cdd8e0;
  --border-avatar: #b4d4d5;
  --border-funding: #f5f6f7;
  --border-field-hover: #7db4b5;

  // Buttons
  --button-primary1-active: #fbaf1d;
  --button-primary1-hover: #fee7bb;
  --button-primary2-active: #fee7bb;
  --button-secondary: #f5f6f7;
  --button-tetriary-active: #ffffff;
  --button-pagination-selected: #e5f2f3;
  --button-pagination-unselected: #f5f6f7;
  --button-notification-selected: #d0e7f4;
  --button-notification-unselected: #053b66;
  --button-radio-button-check-box: #7db4b5;

  // Error Alert
  --error-alert: #ba2c2b;

  // Icons
  --icon-primary1-button: #053b66;
  --icon-primary2-button: #053b66;
  --icon-secondary-tetriary-button: #416c8e;
  --icon-dropdown: #6988a1;
  --icon-pagination: #4c5c68;
  --icon-notification-selected: #053b66;
  --icon-notification-unselected: #d0e7f4;
  --icon-notification-hover: #ffffff;
  --icon-secondary: #4c5c68;

  // Lines
  --line-section: #fbaf1d;
  --line-footer: #e5f2f3;
  --line-section-title-scroll: #cdd8e0;
  --line-header: #fbaf1d;

  // Switcher
  --switcher-selected: #e5f2f3;
  --switcher-unselected: #f5f6f7;

  // Tags
  --tag-primary-background: #e5f2f3;
  --tag-secondary-background: #f5f6f7;

  // Text
  --text-buttons-primary: #103049;
  --text-primary1: #103049;
  --text-primary2: #053b66;
  --text-secondary: #4c5c68;
  --text-accent-1: #459396;
  --text-accent-card: #459396;
  --text-accent-2: #fbaf1d;
  --text-buttons-secondary: #053b66;
  --text-on-the-dark-background: #ffffff;
  --text-search-field-default: #4c5c68;
  --text-primary1-lighten: #416c8e;

  // --v2-main-color: #053B66;
  // --v2-main-color-dark: #011B31;
  // --v2-main-color-dark-navy: #103049;
  // --v2-main-color-lighten1: #D2EBFF;
  // --v2-main-color-lighten2: #475CAB;
  // --v2-main-color-lighten3: #145386;
  // --v2-main-color-navy-shade: #4C5C68;
  // --v2-main-color-new-navy-06: #CDD8E0;
  // --v2-main-color-new-cyan-shade-03: #E5F2F3;
  // --v2-main-color-new-cyan-main: #459396;
  // --v2-main-color-button: #FAB019;

  // Same for all tenant:
  // --v2-white: #FFFFFF;
  // --v2-black: #000000;
  // --v2-dark-gray: #2B2B2B;
  // --v2-main-gray: #676767;

  // --v2-neutral-shade-1: #FAFAF9;
  // --v2-neutral-shade-2: #F3F2F0;
  // --v2-neutral-shade-3: #EDEBE7;
  // --v2-neutral-shade-4: #D9D9D9;
  // --v2-neutral-shade-5: #A7A9AC;
  // --v2-neutral-shade-6: #E0D9CD;
  // --v2-neutral-shade-7: #ACA392;
  // --v2-neutral-shade-8: #565252;

  // --v2-main-color-new-navy-07: #F5F6F7;
}