@import 'styles/common.scss';


.advisoryOrg-Geography {
    margin-bottom: 40px;
    width: 100%;
    
    @include phone() {
      margin-bottom: 24px;
    }
    .profile_field {
        .drop_down_container {
            &__list {
                margin-top: -1px !important;
            }
        }
    }

    &__state {
        .profile_field {
            .drop_down_multi_select_container {
                &__list {
                    margin-top: -2px !important;
                }
            }
        }
    }
}