@import 'styles/common.scss';

.tooltip {
  position: relative;

  &__question_mark {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: -17px;
    top: 21px;

    &__triangle {
      height: 12px;
      width: 12px;

      position: relative;
      top: 3px;
      left: 20px;
    }

    &__rectangle {
      padding: 6px;
      background-color: var(--background-primary-light);
      border-radius: 4px;
      border: 1px solid var(--border-cards);
      text-align: left;
    }
  }
}