@import 'styles/common.scss';

.reset_password__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(var(--neutral-1-rgb), 0.7);
  z-index: 9999;

  .modal_block {
    margin: auto;
    width: 685px;
    background: var(--main-white);
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    border-radius: 8px;

    @include phone() {
      margin-bottom: 0;
      border-radius: 8px 8px 0 0;
    }
  }
}