@import 'styles/common.scss';

.profile_field {
  width: 100%;
  margin-bottom: 24px;

  span {
    color: var(--main-gray);
  }

  &__title {
    margin-bottom: 16px;
    text-align: left;
    @include Montserrat(24px, var(--text-primary1), 500, 140%);

    @include phone() {
      @include Montserrat(18px, var(--text-primary1), 500, 140%);
    }
  }

  &__subtitle {
    margin-bottom: 16px;
    text-align: left;
    @include Roboto(20px, var(--text-secondary), 400, 140%);

    @include phone() {
      @include Roboto(16px, var(--text-secondary), 400, 140%);
      margin-bottom: 16px;
    }
  }

  &_entry {
    position: relative;
    text-align: left;
  }
}