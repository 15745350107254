@import 'styles/common.scss';

.advisory-org-zip-codes {
  &-success-msg {
    @include Roboto(20px, var(--text-accent-1), 400, 150%);
    text-align: left;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'clig' off, 'liga' off;

    margin-bottom: 16px;

    @include phone() {
      font-size: 16px;
    }
  }

  &__zip-codes-empty {
    @include Roboto(20px, var(--error-alert), 400, 150%);
  }

  &__button .btn {
    background-color: var(--button-secondary) !important;
    @include Roboto(16px, var(--text-buttons-secondary), 500, 140%);
    color: var(--text-buttons-secondary) !important;
  }

  .errors_text {
    margin-bottom: 10px;
  }
}