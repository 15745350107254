@import 'styles/common.scss';

.rate_button {
  width: 104px !important;
  box-shadow: 1px 1px 0px var(--secondary-dark-blue);
  border-radius: 4px;
  @include phone() {
    height: 32px;
    width: 80px !important;
  }
}
