@import '../../../styles/common.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  background: var(--background-primary-light);

  &_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 48px;
    height: 64px;
    position: relative;
    gap: 10px;
    box-shadow: 0px 4px 20px -20px #053B66;

    @include tablet() {
      padding: 12px 20px;
    }

    @include phone() {
      padding: 12px 20px;
    }

    .logo {
      max-width: 235px;
      max-height: 56px;

      @include phone() {
        max-width: 170px;
        max-height: 40px;
      }
    }

    .percent {
      grid-column: 1/3;
      grid-row: 1/3;
      justify-self: center;
      @include Roboto(20px, var(--line-header), 700, 120%);

      @include phone() {
        @include Roboto(16px, var(--line-header), 600, 120%);
      }
    }

    .profile {
      justify-self: end;
      display: flex;
      gap: 16px;
      grid-row: 1/3;

      &_messenger {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        width: 40px;
        height: 40px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 50%;

        &__icon {
          width: 25px;
          height: 25px;
        }
      }

      &_avatar {
        position: relative;

        &__wrapper {
          display: flex;
          align-items: center;

          &:hover {
            cursor: pointer;
          }

          .active {
            transform: rotate(180deg);
          }
        }

        &__img {
          .sb-avatar {
            span {
              @include Roboto(14px, var(--text-on-the-dark-background), 700, 120%);

              @include phone() {
                font-size: 12px;
              }
            }
          }
        }

        &__burger {
          background-color: var(--background-primary-light);
          display: flex;
          flex-direction: column;
          position: absolute;
          width: 206px;
          top: 52px;
          right: 0;
          border: 2px solid var(--border-tetriary-button);
          border-radius: 6px;

          &:hover {
            cursor: pointer;
          }

          .burger_item {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 12px 16px;

            @include Montserrat(16px, var(--text-secondary), 400, 150%);

            &__icon {
              width: 17px;
              height: 17px;

              path {
                fill: var(--icon-secondary-tetriary-button);
              }
            }
          }
        }
      }

      &__right-menu {
        display: flex;
        align-items: center;
        justify-self: center;
        @include Roboto(16px, var(--text-primary1), 400, 140%);

        a {
          @include Roboto(16px, var(--text-primary1), 400, 140%);
        }

        .active {
          @include Roboto(16px, var(--text-primary1), 700, 140%);
          border-bottom: 1px solid var(--line-header);
        }

        &>*:not(.horizontal-divider) {
          display: flex;
          align-items: center;
          text-decoration: none;
          padding: 7px 0px 7px 0px;

          &:hover {
            cursor: pointer;
            border-bottom: 1px solid var(--line-section-title-scroll);
          }
        }
      }

      &_menu_button {
        display: none;
        width: 40px;
        height: 40px;

        @include tablet() {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3.98px;
          padding: 8px 10px;
          border: none;
          background-color: var(--button-secondary);
        }

        @include phone() {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3.98px;
          padding: 8px 10px;
          border: none;
          background-color: var(--button-secondary);
        }
      }
    }

    &_lang {
      position: absolute;
      right: 48px;
      top: 8px;

      @include phone() {
        right: 16px;
      }
    }

    &_empty {
      width: 40px;
    }
  }

  &_line {
    width: 100%;
    background-color: var(--shades-8);
    height: 4px;

    &>div {
      background-color: var(--line-header);
      height: 100%;
    }
  }
}