@import "../../../../../styles/common.scss";

.profile_missing_data_success_modal {
  max-width: 740px !important;
  
  &_header-icon {
    font-size: 50px;
    width: 50px;
    color: red;
  }

  &_header {
    margin-bottom: 10px !important;
  }

  &__footer {
    width: 100%;
    height: 88px;
    bottom: 0;
    border-top: 1px solid var(--shades-3);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--shades-2);
    border-radius: 0 0 8px 8px;

    @include phone() {
      height: 62px;
    }

    .icon_right {
      margin: 0 !important;
    }
  }

  &_description {
    color: var(--main-gray);

    @include phone() {
      font-size: 16px;
    } 
  }
}
