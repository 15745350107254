@import 'styles/common.scss';

.bullets_required {
  background: var(--main-white);
  border-radius: 4px;
  border: 2px solid var(--error-alert);
  padding: 8px;
  position: absolute;
  top: 50px;
  left: 22px;
  z-index: 2;
  min-width: min-content;
  max-width: 455px;

  @include phone() {
    top: 43px;
    left: 10px;
    max-width: 315px;
  }

  &__triangle {
    &>div {
      &:nth-child(1) {
        width: 12px;
        border: 1px solid var(--error-alert);
        position: absolute;
        top: -7px;
        left: 15px;
        border-radius: 1px 0px 1px 1px;
        transform: rotate(120deg);
        z-index: 2;
        background: var(--error-alert);
      }

      &:nth-child(2) {
        width: 9px;
        border: 2px solid white;
        position: absolute;
        top: -2px;
        left: 19px;
        border-radius: 0px 1px 0px 0px;
        z-index: 1;
      }

      &:nth-child(3) {
        width: 12px;
        border: 1px solid var(--error-alert);
        position: absolute;
        top: -7px;
        left: 20px;
        transform: rotate(62deg);
        border-radius: 1px 1px 0px 1px;
        z-index: 2;
        background: var(--error-alert);
      }
    }
  }

  &__text {
    @include Roboto(16px, var(--text-primary1), 500, 150%);

    @include phone() {
      @include Roboto(14px, var(--text-primary1), 500, 150%);
    }

    &.additional-error {
      @include Roboto(16px, var(--error-alert), 500, 150%);

      @include phone() {
        @include Roboto(14px, var(--error-alert), 500, 150%);
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 5px;

    &_item {
      display: flex;
      gap: 6px;

      .icon {
        width: 15px;
        height: 15px;
      }

      p {
        @include Roboto(14px, var(--text-primary1), 400, 150%);

        @include phone() {
          @include Roboto(12px, var(--text-primary1), 400, 150%);
        }
      }
    }
  }
}