@import 'styles/common.scss';

.profile_notification__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 37px 32px 41px 32px;

  @include phone() {
    padding: 12px 16px 16px;
  }

  .text {
    @include Roboto(13px, var(--main-gray), 500, 120%);
    
    opacity: 0.7;

    @include phone() {
      @include Roboto(14px, var(--main-gray), 500, 120%);
    }
  }

  &__icon {
    box-sizing: content-box;

    width: 20px;
    height: 20px;
    
    opacity: 0.7;
    padding: 5px 0;
    margin-bottom: 4px;
    
    @include phone() {
      width: 16px;
      height: 16px;
      padding: 4px 0;
      margin-bottom: unset;
    }
  }
}