@import 'styles/common.scss';

.selectable_block {
  position: relative;
  padding: 32px 36px 36px 24px;
  border: 2px solid var( --border-avatar);
  border-radius: 4px;
  background: var(--shades-1);

  @include phone() {
    margin-right: 0;
    margin-bottom: 11px;
    width: 100%;
    min-height: 84px;
    min-height: none;
  }
  
  @include phone() {
    padding: 24px 28px 24px 16px;
  }
  
  &.active {
    border: 2px solid var(--border-avatar);
  }
  
  &:hover {
    cursor: pointer;
    border: 2px solid var(--border-field-hover);
  }
  
  &__title {
    @include Roboto(20px, var(--text-primary1), 400, 140%);
    
    &:hover {
      cursor: pointer;
    }
    
    @include phone() {
      @include Roboto(20px, var(--text-primary1), 400, 140%);
    }
  }
  
  &__text {
    @include Roboto(16px, var(--text-secondary), 400, 140%);
    
    &:hover {
      cursor: pointer;
    }
    
    @include phone() {
      display: flex;
      align-items: center;
      @include Roboto(16px, var(--text-secondary), 400, 140%);
    }
  }

  &__circle {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 14px;
    background-color: var(--main-white);
    border-radius: 50%;
    border: 2px solid var(--button-radio-button-check-box);
    right: 14px;
    
    &.active {
      border: 7px solid var(--button-radio-button-check-box);
    }
    
    @include phone() {
      bottom: 8px;
      right: 8px;
    }
  }
  
  &__box {
    width: 24px;
    height: 24px;
    font-size: 24px;
    position: absolute;
    top: 14px;
    border: 0;
    background-color: var(--main-white);
    border: 2px solid var(--button-radio-button-check-box);
    right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    
    &.active {
      background: var(--button-radio-button-check-box);
      border: 2px solid var(--button-radio-button-check-box);
    }
    
    @include phone() {
      bottom: 8px;
      right: 8px;
    }
  }
  
  &__icon {
    position: absolute;
    bottom: 14px;
    right: 14px;
    @include phone() {
      bottom: 10px;
      right: 10px;
    }
  }
}
