@import 'styles/common.scss';
.modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(var(--neutral-1-rgb), 0.7);
  z-index: 9999;

  .modal_block {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: auto;
    max-width: 1112px;
    max-height: 738px;
    height: 100%;
    background: var(--main-white);
    padding: 0;
    align-items: center;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    @include phone() {
      max-width: 100%;
      width: 100%;
      margin-bottom: 0;
      border-radius: 8px 8px 0 0;
    }
    .rc-rate {
      font-size: 60px;
      @include phone {
        font-size: 40px;
      }
    }
    .modal_close_button {
      position: absolute;
      display: none;
      top: 10px;
      right: 10px;
      border-radius: 50%;
      overflow: hidden;
      z-index: 101;

      .filled {
        padding: 6px 6px;
        height: auto;
        background-color: var(--main-gray);

        svg {
          margin-left: 1px;
          margin-bottom: 1px;
        }
      }

      @include phone {
        display: block;
      }
    }
    .text_area__field {
      margin-top: 24px;
    }
    &.rate_modal {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 480px;
      max-width: 580px;
      height: auto;
      padding-top: 56px;
      @include phone() {
        min-height: unset;
      }
      .modal_body {
        width: 100%;
        flex-grow: 1;
        padding: 24px 48px;
        text-align: center;
        overflow: auto;

        @include phone() {
          padding: 24px;
        }

        &_title {
          margin-block: 0 6px;

          @include Montserrat(20px, var(--text-primary1), 500, 120%);

          @include phone() {
            font-size: 18px;
          }

          span {
            @include Montserrat(28px, var(--text-accent-1), 400, 120%);
          }
        }

        &_subtitle {
          // margin-bottom: 24px;

          @include Roboto(20px, var(--text-secondary), 400, 150%);

          @include phone() {
            font-size: 16px;
          }
        }

        &_skip {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // margin-top: 10px;
          p {
            @include Roboto(20px, var(--shades-7), 400, 150%);
            @include phone() {
              @include Roboto(18px, var(--shades-7), 400, 150%);
            }
          }
        }
      }

      .modal_footer {
        width: 100%;
        padding: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--shades-2);
        border-top: 1px solid var(--shades-3);

        @include phone() {
          padding: 7px;
        }
        
        .btn {
          @include Montserrat(18px, var(--text-buttons-primary), 500, 100%);
        }

        .buttons_row {
          display: flex;
          min-width: 82px;

          .cancel_button {
            margin-left: 20px;
            margin-block: auto;

            .text {
              border: none;
              @include Montserrat(18px, var(--main-gray), 500, 100%);
            }
          }
        }

        .step_counter {
          justify-self: center;

          @include Roboto(20px, var(--main-gray), 400, 150%);

          @include phone() {
            font-size: 16px;
            margin-left: 0;
          }
        }
      }
    }
  }
  .selectors_container{
     display: flex;
     align-items: center;
     gap: 24px;
     margin-top: 32px;
     margin-bottom: 56px;
    .select_button{
      width: 220px;
      height: 56px;
      
    }
  }
}
