@import 'styles/common.scss';

.custom_modal_container_menu {

  .custom_modal {
      height: 100%;
      width: 80%;
      margin-right: calc(100% - 80%);
   

    .custom_upper_line {
      display: none;
    }
    .custom_inner_container {
      margin: 48px 48px 12px 48px !important;
      height: 100%;
    }

    .modal-close-icon {
      top: 16px;
      right: 13px;
      left: auto;
    }
  }
}
