@import "../../../styles/common.scss";

#shutdown {
  -webkit-user-select: none;
}

#shutdown.shutdown {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  @include phone() {
    top: 45%;
  }
}

.shutdown {
  position: relative;
  z-index: 9999;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  &_wrapper {
    position: relative;
    .content {
      padding: 0 20px;
      &__img {
        max-width: 700px;
        width: 100%;
        margin-bottom: 80px;
        @include phone() {
          margin-bottom: 62px;
        }
      }
      &__title {
        margin-bottom: 32px;

        @include Montserrat(48px, var(--main-blue), 600, 120%);
        @include phone() {
          @include Montserrat(32px, var(--main-blue), 600, 120%);
          margin-bottom: 16px;
        }
      }
      &__description {
        display: block;
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
        @include Roboto(32px, var(--main-blue), 400, 150%);
        @include phone() {
          @include Roboto(18px, var(--main-blue), 400, 150%);
        }
      }
    }
  }
}

.background-img-1 {
  position: absolute;
  top: 0;
  right: 0;
}
.background-img-2 {
  position: absolute;
  bottom: 0;
  left: 0;

  @include phone() {
    left: -360px;
  }
}
