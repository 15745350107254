@import 'styles/common.scss';

.code-input-error {
  margin-top: 12px;
  text-align: center;
  @include Roboto(16px, var(--error-alert), 400, 150%);
  @include phone() {
    @include Roboto(12px, var(--error-alert), 400, 150%);
  }
  letter-spacing: -0.01em;
}

.react-code-input {
  input {
    @include Montserrat(24px, var(--text-primary1), 500, 120%);
    @include phone() {
      @include Montserrat(18px, var(--text-primary1), 500, 120%);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
    &[type=number] {
      -moz-appearance: textfield;
    }
  
    &:first-child {
      margin-left: 0 !important;
    }

    &:focus-visible {
      outline: var(--border-avatar) auto 1px;
    }
  }
}