@import 'styles/common.scss';

.help {
  position: fixed;
  top: 96px;
  right: 48px;
  z-index: 100;

  @include phone() {
    top: 94px;
    right: 20px;
  }

  .btn {
    padding: 9px 10px !important;
    gap: 10px !important;
    border-radius: 4px;
    border: 1px solid var(--border-tetriary-button);
    background: var(--button-tetriary-active);
    box-shadow: 0.995px 0.995px 0px 0px #E2EFEE;

    .children {
      @include Roboto(16px, var(--text-primary1), 500, 140%);
      margin-top: 3px;
    }
  }

  .help_modal {
    background-color: var(--main-white);
    display: flex;
    flex-direction: column;
    position: absolute;
    min-width: 210px;
    width: max-content;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    border: 2px solid var(--shades-3);
    border-radius: 6px;

    &:hover {
      cursor: pointer;
    }

    @include phone() {
      right: 0;
      left: unset;
      transform: translate(0, 0);
    }

    &_item {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 12px 16px;
      @include Roboto(16px, var(--main-gray), 400, 150%);
      letter-spacing: -0.01em;
      text-decoration: none;

      &__icon {
        width: 20px;
        height: 20px;

        path {
          fill: var(--main-gray);
        }
      }
    }
  }
}