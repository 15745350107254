@import "../../../styles/common.scss";

.login_page {
  // width: 452px;
  // margin: auto;

  &.email_password_form {
    margin-top: 50px;

    @include phone {
      margin-top: 20px;
    }
  }

  @include phone {
    width: 100%;
    margin: unset;
  }
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    text-align: center;

    @include phone {
      margin-bottom: 26px;
    }

    p {
      &:nth-child(1) {
        @include Montserrat(32px, var(--text-primary1), 500, 120%);
        margin-bottom: 12px;

        @include phone {
          @include Montserrat(24px, var(--text-primary1), 500, 120%);
        }
      }

      &:nth-child(2) {
        @include Roboto(20px, var(--text-secondary), 400, 150%);

        @include phone {
          @include Roboto(16px, var(--text-secondary), 400, 150%);
        }
      }
    }
  }

  .entry {
    margin-bottom: 16px;
    width: 452px;
    margin: auto;
    @include phone {
      margin-bottom: 8px;
      width: 100%;
      margin: unset;
    }

    .verification-code-input {
      margin: auto;
      max-width: 380px;
      margin-bottom: 32px;
    }

    &_item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &:nth-child(1) {
        margin-bottom: 40px;

        @include phone {
          margin-bottom: 24px;
        }
      }

      .title {
        @include Montserrat(24px, var(--text-primary1), 500, 120%);
        margin-bottom: 16px;

        @include phone {
          @include Montserrat(16px, var(--text-primary1), 500, 120%);
          margin-bottom: 8px;
        }
      }
    }
  }

  .login_page__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 452px;
    margin: auto;
    @include phone() {
      width: 100%;
      margin: unset;
    }
    .link_wrapper {
        a {
          @include Roboto(16px, var(--text-accent-1), 400, 150%);
      }

      &:nth-child(1) {
        align-self: flex-end;
        margin-bottom: 24px;

        @include phone {
          margin-bottom: 24px;
        }
      }
    }

    .button_wrapper {
      margin-bottom: 32px;

      @include phone {
        margin-bottom: 24px;
      }
      button .children {
        @include Roboto(20px, var(--text-buttons-primary), 500, 120%);
        @include phone() {
          @include Roboto(16px, var(--text-buttons-primary), 500, 120%);
        }
      }
    }

    .auth_text .link_wrapper a {
      @include Roboto(16px, var(--text-primary1), 400, 120%);
    }
  }

  .code-input-error {
    a {
      font-weight: 500;
      color: var(--error-alert);
    }
  }
}
