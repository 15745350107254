@import 'styles/common.scss';

.image_upload_field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 42px;

  .upload_avatar__img {
    margin-bottom: 8px;
  }

  .btn {
    width: 140px;
    height: 41px;
    padding: 9px 10px;
    @include Roboto(16px, var(--text-primary1), 500, 140%);
  }

  &__validation_error,
  &__max_size {
    margin-top: 8px;

    @include Roboto(14px, var(--text-secondary), 400, 140%);
  }

  &__validation_error {
    color: var(--error-alert);
  }
}
