@import 'styles/common.scss';
.notification_block {
  position: relative;

  width: 468px;
  min-height: 109px;

  display: flex;
  align-items: flex-start;
  background: var(--main-white);

  padding: 12px;
  margin-bottom: 4px;
  border: 1px solid var(--shades-3);
  border-radius: 8px;

  @include phone() {
    width: 327px;
    right: auto;
    min-height: 88px;
  }

  &.empty {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
      @include Roboto(13px, var(--main-gray), 500, 120%);
      opacity: 0.7;
      @include phone() {
        @include Roboto(14px, var(--main-gray), 500, 120%);
      }
    }
    svg {
      width: 24px;
      height: 24px;
      opacity: 0.7;
      margin-bottom: 4px;
      @include phone() {
        width: 16px;
        height: 16px;
      }
    }
  }
  & > .notification_logo_container {
    margin-right: 8px;
    .sb-avatar span {
      font-size: 14px;
      @include phone {
        font-size: 12px;
      }
    }
  }

  .title {
    @include Roboto(16px, var(--shades-7), 500, 120%);
    letter-spacing: -0.01em;
    margin-bottom: 4px;
    @include phone() {
      @include Roboto(13px, var(--shades-7), 500, 120%);
    }
    span {
      @include Roboto(16px, var(--main-gray), 500, 150%);
      letter-spacing: -0.01em;
      @include phone() {
        @include Roboto(13px, var(--main-gray), 500, 120%);
      }
    }
  }
  .text {
    @include Roboto(16px, var(--main-gray), 500, 150%);
    letter-spacing: -0.01em;
    margin-bottom: 11px;
  }
  .btns {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 12px;

    .button_wrapper {
      button {
        width: 104px;
        height: 32px;
      }
    }
  }
  .time {
    @include Roboto(12px, var(--shades-7), 500, 120%);
    margin-top: 6px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
  }
  .marked {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 1.5px 6px;
    background: var(--shades-2);
    border-radius: 4px;
    width: fit-content;
    p {
      letter-spacing: -0.01em;
      @include Roboto(16px, var(--secondary-teal), 400, 150%);
    }
    svg {
      width: 13px;
      height: 13px;
    }
  }
  .comment {
    @include Roboto(16px, var(--main-gray), 400, 150%);
    letter-spacing: -0.01em;
    @include phone() {
      @include Roboto(15px, var(--main-gray), 400, 150%);
    }
  }
  .share {
    @include Roboto(16px, var(--main-gray), 500, 150%);
    margin-bottom: 11px;
  }
  .access_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
    &__item {
      display: flex;
      align-items: center;
      padding: 2px;
      gap: 6px;
      background: var(--shades-2);
      border-radius: 4px;
      p {
        letter-spacing: -0.01em;
        @include Roboto(16px, var(--secondary-teal), 400, 150%);
      }
      .avatar__img .sb-avatar span {
        font-size: 12px;
      }
    }
  }
  .session_link {
    @include Montserrat(12px, var(--main-gray), 500, 150%);
    display: flex;
    gap: 5px;
    align-items: center;
    @include phone() {
      display: none;
    }
    a {
      color: var(--main-gray);
    }
    svg {
      width: 10px;
      height: 10px;
    }
    .dot {
      margin: 0 4px;
      width: 1px;
      height: 1px;
      background-color: var(--main-gray);
    }
    &.phone {
      display: none;
      width: 168px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include phone() {
        display: flex;
      }
    }
  }
  .scheduled {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 10px;
    &_item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      @include phone() {
        gap: 6px;
        align-items: flex-start;
      }
      &__name {
        width: 87px;
        @include Roboto(13px, var(--shades-7), 500, 120%);
        @include phone() {
          width: 56px;
          min-width: 56px;
          @include Roboto(14px, var(--shades-7), 500, 150%);
        }
      }
      &__content {
        @include Roboto(16px, var(--main-gray), 400, 150%);
        @include phone() {
          @include Roboto(15px, var(--main-gray), 400, 150%);
        }
      }
    }
  }

  &.booked_session,
  &.scheduled_session,
  &.rescheduled_session,
  &.cancelled,
  &.advisor_cancelled {
    @include phone() {
      width: 361px;
    }
  }

  .red_circle {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: var(--error-alert);
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}
