.tooltip_triangle {
  &__triangle {
    fill: white;
    stroke: #A7A9AC;
    stroke-width: 150;
    transform-origin: 250px 250px;
  }

  &__bline {
    stroke: white;
    stroke-width: 210;
  }
}