@import 'styles/common.scss';

.program_and_resources_modal {
   
    &__close_icon {
        left: unset !important;
        right: 10px;

        svg {
            width: 36px !important;
            height: 36px !important;
        }
    }

    &__inner_container {
      max-height: 80vh;
      overflow-y: auto;
    }

    &__modal_content {
      padding: 5px;
    }

  .add_resource {
    &__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .title {
        @include Montserrat(32px, var(--text-primary1), 500, 130%);
      }
      .sub_title {
        @include Roboto(20px, var(--text-secondary), 400, 140%);
      }
    }
    &__content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 32px;
      margin: 40px 0px;

      .select {
        width: calc(50% - 16px);

        .icon-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36px;
          border-radius: 90px;
          background: var(--background-glossary);
        }

        &__elem {
          &__icon {
            margin-bottom: 11px;
            border-radius: 90px;
            background-color: var(--background-glossary);
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include phone() {
              margin-bottom: 0;
              margin-right: 10px;
            }
          }
          &__text {
            @include Roboto(20px, var(--text-primary1), 400, 140%);
            text-align: left;
            @include phone() {
              @include Roboto(16px, var(--text-primary1), 400, 140%);
            }
          }
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      .btn {
        width: 240px;
        height: 48px;
        padding: 8px 16px;
        @include Roboto(20px, var(--text-buttons-primary), 500, 140%);
      }
    }
  }

}
