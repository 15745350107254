@import 'styles/common.scss';

.entry_field {
  position: relative;
  width: 100%;
  input {
    background-color: var(--shades-1);
    border: 2px solid var(--border-avatar);
    border-radius: 4px;
    padding: 13px 24px;
    width: 100%;
    @include Roboto(20px, var(--text-primary1), 400, 140%);
    position: relative;
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
    &::placeholder {
      @include Roboto(20px, var(--text-secondary), 400, 150%);
      @include phone() {
        @include Roboto(16px, var(--text-secondary), 400, 150%);
      }
    }

    &:focus {
      caret-color:var(--text-primary1);
      color: var(--text-primary1) !important;
    }

    &:hover {
      border: 2px solid var(--border-field-hover);
    }

    @include phone() {
      padding: 10px 16px;
      @include Roboto(16px, var(--text-primary1), 400, 140%);
    }
  }

  .error {
    &_icon {
      position: absolute;
      right: 18px;
      top: 20px;
      &:hover {
        cursor: pointer;
      }
      @include phone {
        right: 16px;
        top: 16px;
        width: 15px;
        height: 14px;
      }
    }
    &_text {
      @include Roboto(15px, var(--error-alert), 500, 150%);
      color: var(--error-alert);
    }
  }

  &.focus {
    input {
      color: var(--main-dark-gray);
      border: 2px solid var(--border-field-hover);
    }
  }
  &.error {
    input {
      color: var(--error-alert) !important;
      border: 2px solid var(--error-alert) !important;
    }
  }
}
