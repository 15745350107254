@import 'styles/common.scss';

.profile_field {
  margin-bottom: 40px;
  width: 100%;

  @include phone() {
    margin-bottom: 24px;
  }

  &__title {
    @include Montserrat(24px, var(--text-primary1), 500, 140%);

    margin-bottom: 16px;

    @include phone() {
      @include Montserrat(18px, var(--text-primary1), 500, 140%);
    }
  }

  &__subtitle {
    @include Roboto(20px, var(--text-secondary), 400, 140%);

    margin-bottom: 16px;

    @include phone() {
      @include Roboto(16px, var(--text-secondary), 400, 140%);
    }
  }
}

.pac-container:after {
  /* Removing 'powered by Google' */
  background-image: none !important;
  height: 0px;
}

.pac-container {
  box-shadow: inset 0px -1px 0px #e3dccf;
  width: 100%;
  border: 2px solid var(--shades-6);
  background: var(--main-white);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  max-height: 42vh;
  overflow: auto;
  z-index: 2;
  @include Roboto(20px, var(--main-gray), 400);

  &:focus {
    color: rgba($color: var(--main-gray), $alpha: 0.6);
  }


  @include phone() {
    @include Roboto(16px, var(--main-gray), 400);
  }

}


.pac-item {
  width: 100%;
  border-top: 2px solid var(--shades-6);
  padding: 13px 24px;
  line-height: 30px !important;
  @include Roboto(13px, var(--main-dark-gray), 500);

  @include phone() {
    padding: 10px 16px;
  }

  &:nth-child(1) {
    border: 0;
  }

  &:hover {
    cursor: pointer;
    background: var(--shades-2);
  }
}